import React from 'react'
import Loadable from 'react-loadable'
import LoadingIndicator from './components/LoadingIndicator/LoadingIndicator'

/**
 * Defines module routes, you can use react-router-dom route parameters
 * as properties in each object in routes array for example "exact: true"
 * 
 * Each route lazy loads a component if its path matches the current path. 
 */

const routes = [
    {
        path: '/Notifcation',
        component: Loadable({
            loader: () => import('./components/Notification/Notification'),
            loading: () => (<LoadingIndicator/>)
        })
    },
    {
        path: '/settingProfile',
        isAuth: true,
        component: Loadable({
            loader: () => import('./components/profile/profile'),
            loading: () => (<LoadingIndicator/>)
        })
    },
]

export default routes