import { SET_LOCALE } from "../../modules/Common/store/action-types";
import moment from 'moment'
import 'moment/locale/ar'
import 'moment/locale/en-ca'

const switchDirectionMiddleware = store => next => async (action) => {
    
    if(action.type === SET_LOCALE){
        if(action.payload === 'english'){
            document.body.classList.remove('rtl')
            document.querySelector("html").setAttribute("lang", "en")
            moment.locale('en-ca')
        }
        else {
            document.body.classList.add('rtl')
            document.querySelector("html").setAttribute("lang", "ar")
            moment.locale('ar')
        }
    }
  
    return next(action);
};

export default switchDirectionMiddleware;