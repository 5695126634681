export const responseMessagesAR = {
    200:    'طلب ناجح',
    100:	'فشل في إرسال الكود',
    101:	'الكود غير صالح',
    102:	'كود التوثيق مطلوب',
    103:	'إنتهاك لفترة إرسال الرسالة النصية القصيرة',
    104:	'المستخدم غير موجود',
    105:	'خطئ في التحقق',
    106:	'خانة التسجيل موجودة بالفعل',
    107:	'خانة نسيت كلمة المرور مطلوبة',
    108:	'رقم الهاتف موجود بالفعل',
    109:	'رمز التحديث غير صالح',
    110:	'البريد الإلكتروني غير مؤكد',
    111:	'رقم الهاتف غير مؤكد',
    112:	'تم الوصول للحد الأقصى من إرسال الرمز',
    113:	'رقم الهاتف مطلوب',
    114:	'البريد الإلكتروني مطلوب',
    115:	'البريد الإلكتروني موجود بالفعل',
    116:	'البريد الإلكتروني أو كلمة المرور خاطئين',
    117:	'البريد الإلكتروني أو كلمة المرور خاطئين',
    118:	'النص المخصص مطلوب',
    119:	'القائمة المخصصة مطلوبة',
    120:	'تطبيق غير صالح',
    121:	'فشل في تحديث بيانات المستخدم',
    122:	'رمز الفيسبوك غير صالح',
    123:	'رمز الفيسبوك غير موجود',
    124:	'البريد الإلكتروني أو كلمة المرور خاطئين',
    125:	'رمز جوجل غير موجود',
    126:	'رمز جوجل غير صالح',
    127:	'نوع التسجيل غير صالح',
    128:	'نوع تسجيل الدخول غير صالح',
    129:	'المستخدم مسجل بإحد شبكات التواصل بالفعل',
    131:    'كود إعادة تعين كلمة المرور غير صالح',
    138:    'كلمة المرور القديمة ليست صحيحة',
}