import {
    SET_LOCALE,
    UPDATE_CONNECTION_STATUS,
    LIST_USER_NOTIFICATIONS,
    SET_NOTIFICATION_STATUS,
    PREPEND_NEW_NOTIFICATION,
    SET_NOTIFICATIONS_COUNTER,
    SET_UPLOADING_STATUS,
    SHOW_EDITOR_UPLOADER,
    UPDATE_EVENT_DURATION,
    LOG_EVENT,
    SHOW_MATH_MODAL,
    SET_ACTIVE_MESSAGE,
    APPEND_NEW_MESSAGE,
} from './action-types'

export function setLocale(payload){
    return {
        type: SET_LOCALE,
        payload,
    }
}

export function updateConnectionStatus(payload){
    return {
        type: UPDATE_CONNECTION_STATUS,
        payload,
    }
}

export function listUserNotifications(payload){
    return {
        type: LIST_USER_NOTIFICATIONS,
        payload
    }
}

export function setNotificationStatus(payload){
    return {
        type: SET_NOTIFICATION_STATUS,
        payload
    }
}

export function prependNewNotification(payload) {
    return {
        type: PREPEND_NEW_NOTIFICATION,
        payload
    }
}

export function setNotificationsCounter(payload) {
    return {
        type: SET_NOTIFICATIONS_COUNTER,
        payload
    }
}

export function setUploadingStatus(payload) {
    return {
        type: SET_UPLOADING_STATUS,
        payload
    }
}

export function showEditorUploader(payload) {
    return {
        type: SHOW_EDITOR_UPLOADER,
        payload
    }
}

export function showMathModal(payload){
    return {
        type: SHOW_MATH_MODAL
    }
}

export function setActiveMessage(payload) {
    return {
        type: SET_ACTIVE_MESSAGE,
        payload
    }
}

export function appendNewMessage(payload) {
    return {
        type: APPEND_NEW_MESSAGE,
        payload
    }
}

export function logEvent(payload){
    return {
        type: LOG_EVENT,
        payload
    }
}

export function updateEventDuration(payload){
    return {
        type: UPDATE_EVENT_DURATION,
        payload
    }
}