import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import registerServiceWorker from './registerServiceWorker';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga'

Sentry.init({
    dsn: "https://d17df04f28cc48d895b14366ec6af089@o1037510.ingest.sentry.io/6020072",
    sentry: true,
    xhr: true,
    environment:"2.5.5",
    sampleRate: 0.25
});
ReactGA.initialize('UA-113313413-9')


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// registerServiceWorker()
