import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { useLocation } from "react-router-dom"
import routes from './routes';
import {createBrowserHistory} from 'history'
import {connect} from 'react-redux'
import ReactGA from "react-ga";
import Error404 from '../modules/Errors/components/Error404/Error404';
import divig from "../resources/images/teacher/divig.svg";
import closeDiv from "../resources/images/teacher/closeDiv.svg";
import { listClassRooms, listClassRoomSubjects } from '../modules/Teacher/services';
import { setActiveSubject } from '../modules/Teacher/store/actions';

const history = createBrowserHistory({forceRefresh: true})

history.listen(location => {
	ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname)
})

/**
 * Detects if user is idle within a specific amout of ms
 * @param {*} ms 
 * @param {*} cb 
 */
function detectIdle(ms, cb) {
    var wait = setTimeout(cb, ms);
    document.onfocus = document.onmousedown = document.onmouseenter = document.onmouseleave = document.onkeydown = document.onkeypress = document.onkeyup = function (e) {
		clearTimeout(wait);
        wait = setTimeout(cb, ms);
	};
	document.addEventListener("visibilitychange", function() {
		if(document.hidden){
			clearTimeout(wait);
		}
	})
}

/**
 * Returns a public component
 * @param {Object} props 
 */

const PublicRoute = ({ isAuthenticated, component: Component, ...rest }) => {
	const { pathname } = useLocation();
	useEffect(() => {
		document.body.scrollTop = 0
		ReactGA.pageview(window.location.pathname)
	}, [pathname])
	return (<Route {...rest} render={props => (<Component {...props} allowedType={rest.allowedType}/>)} />)		

}

/**
 * Returns a private component if user is authenticated and have sufficient permissions
 * @param {Object} props 
 */
const PrivateRoute = ({ component: Component, isAuthenticated, dispatch ,roles, ...rest }) => {
	const [isIdle, setIdleState] = useState(false);
	const [openDiv, setOpenDivState] = useState(false);
	const [classRooms, setClassrooms] = useState([])
	const { pathname } = useLocation();
	useEffect(() => {
		document.addEventListener("visibilitychange", function() {
			if (!document.hidden){
				detectIdle(1800000, function () {
					setIdleState(true)
				})
			}
		})
		document.body.scrollTop = 0
		ReactGA.pageview(window.location.pathname)
		fetchClassRoomsList()
	}, [pathname])

	function fetchClassRoomsList(){
		listClassRooms(
		  (res) => {
			try {
			  setClassrooms(res.data)
			} catch (error) {
			  console.log(error);
			}
		  },
		  (errors) => {
			  console.log(errors)
		  }
		);
	};

	function fetchClassRoomSubjects(id, cb){
		listClassRoomSubjects(id, (res) => {
		  cb(res.data)
		}, (errors) => {
		  console.log(errors)
		})
	}

	
	const MainHeader = React.lazy(() => import('../modules/Common/components/MainHeader/MainHeader'));
	return (
		<div className="teacher-wrapper"> 
		<Suspense fallback='..'>
			<MainHeader />
		</Suspense>
		<Route {...rest} render={props => (
			!isIdle ? (isAuthenticated
				? (
					(rest.allowedRoles ? rest.allowedRoles.every(role => roles.indexOf(role) > -1) : true) ? 
					<Component allowedType={rest.allowedType} nestedRoutes={rest.nestedRoutes} path={rest.path} {...props} /> : <Redirect to={{
						pathname: '/error401',
						state: { from: props.location }
					}}/>
				)
				: <Redirect to={{
					pathname: '/signin',
					state: { from: props.location },
				}} />) : <Redirect to={{
					pathname: '/timed-out',
					state: { from:  props.location}
				}}/>
		)} />
			<div className={`storyInfo-responsive-overlay ${openDiv ? "show" : undefined}`}>
			<div className={`storyInfo-responsive-wrapper ${openDiv ? undefined : "none"}`}>
				{classRooms.map((classRoom, i) => (
				<div
					className="storyInfo-responsive"
					key={i}
					onClick={(e) => {
					e.preventDefault();
					fetchClassRoomSubjects(classRoom.classRoomId, (subjects) => {
						dispatch(setActiveSubject(subjects[0].id))
						history.push({
							pathname: `/classroom/${classRoom.name.replace('/', '-').replace(/ /g, '')}?at=classRoom`,
							state: {
								id: classRoom.classRoomId, 
								subjects: subjects,
								classRooms: classRooms 
							}
						})
					})
					}}
				>
					<div className="storyInfo-responsive__title">
					<span className="type-blue"></span>
					<p>{classRoom.name}</p>
					</div>
					<div className="storyInfo-responsive__details">
					<p>{classRoom.division}</p>
					<p>{classRoom.educationalStage}</p>
					</div>
				</div>
				))}
			</div>
			</div>
			<div
			className="float-btn"
			onClick={() => {
				setOpenDivState(!openDiv)
			}}
			>
			<img src={openDiv ? closeDiv : divig} alt="filter" />
			</div>
		</div>
	)
}

const Routes = ({isAuthenticated, roles, dispatch}) => {
	return (
		// forceRefresh={true}
	<Router hisotry={history}>
		<Switch>
			{routes.map((route, i) => {
			if(route.isAuth){
				return (
				<PrivateRoute dispatch={dispatch} roles={roles} isAuthenticated={isAuthenticated} key={i} {...route} />
				)
			}
			return( <PublicRoute isAuthenticated={isAuthenticated} roles={roles} key={i} {...route} />)
			})}
			<Route component={Error404}/>
		</Switch>
	</Router>
)}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
	roles: state.auth.userRoles,
	uploadingStatus: state.common.uploadingStatus
  }
}

export default connect(mapStateToProps)(Routes)
