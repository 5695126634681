export const urdu = {
	auth: {
		login: "login",
		logout: "Logout",
		rememberMe: "Remember me",
		signIn: "Sign in",
		email: "Email",
		mail_massage: "The input is not valid e-mail!",
		mail_req_massage: "Please input your e-mail!",
		password_placeholder: "Enter your password",
		email_placeholder: "Enter your email",
		plaeaseEnterMail:
			"Please Enter your email then click the button below to request another one.",
		password: "Password",
		forgetPassword: "Forget Password?",
		Forget_Password: "Forget Password",
		sammary:
			"Please enter your email so that we can  send you a rest password email.",
		passwordMassage: "Please input your password!",
		backToSignin: "Back to sign in",
		sendMail: "Send email",
		sorry505:
			"The server encountered an internal error or misconfiguration and  was  unable to complete your request",
		resetSent: "Reset code was sent successfully",
		confirmPassword: "Confirm new password",
		restPass_placeholder: "Confirm your password",
		confirm_massage: "Please confirm your password!",
		Reset: "Reset",
		cancle: "Cancel",
		CheckInpoxp1: "Please check your inbox or spam folder at",
		CheckInpoxp2: "For a link to reset your password .",
		GoToMail: "Go to your mail",
		Ok: "OK",
		oops: "Oops!! This URL Is Not Valid Anymore.",
		ResendMail: "Resend mail",
		passwordmin: "Password must be min 6 characters",
		passwordmax: "Password must be max 20 characters",
		passwordexpresion:
			"Password must be at least 8 characters including capital and small letters.",
		profile: "Profile",
		personalInfo: "Personal information",
		name: "Name",
		nameMassage: "Please input your name!",
		namePlaceholder: "Enter your name",
		Security: "Security",
		Account: "Account",
		Change: "Change",
		Deactivate: "Deactivate",
		DeactivateAccount: "Deactivate account",
		SaveChanges: "Save changes",
		ChangePassword: "Change password",
		NoCancel: "No, Cancel",
		YesDeactivate: "Yes, Deactivate",
		PasswordsChanged: "Passwords changed successfully",
		AccountDeactivated: "Account Deactivated!",
		YourAccountDeactivated:
			"Your account have be deactivated, please be informed that you’ll never be able to login again or restore your account",
		Delete: "Delete",
		JoinYourClassNow: "Login to your account Now!",
		Studentcode: "Student code",
		Studentcoderequired: "please input Student code",
		AreYouSoure: "Are you sure you want to delete this data ?",
		YesSave: "Yes, Save",
		NoSave: "No, Don’t Save",
		NewPassword: "New password",
		Cancel: "Cancel",
		EnterPassFor: "Please enter Your Password for",
		TimedOut:
			"You are being timed-out due to inactivity .please choose to stay ",
		SinginOrLogout: "Signed-in or log-out .",
		otherwise: "Otherwise , you will be logged out automatically",
		minute: "after 1 minute.",
		stay: "Stay logged-in",
		inactivityMessage: "You have been logged-out due to inactivity",
		incorrectOldPassword: "Old password is incorrect",
		Imagemustsmallerthan5MB: "Image must smaller than 5MB!",
		YoucanonlyuploadJPGPNG: "You can only upload JPG/PNG file!",
		createAccount: "Create account",
		aleadyHaveAccount: "Aleady have an account ?",
		signUp: "Sign up",
	},
	notifi: {
		MarkAll: "Mark all reeded",
		NotifiContent: "Welcome back to Starwallet labs admin panel",
		fiveMin: "5 Mins ago",
		Delete: "Delete",
	},
	validationMessages: {
		shortDescriptionLength: "Short description must be less than 70 character",
		descriptionLength: "Description must be less than 500 characters",
		titleLength: "Title must be less than 30 character",
		titleLengthMax: "Title must be less than 60 character",
		titleLengthMin: "Title must be at least 3 characters",
		firstNameLength: "First name must be less than 20 character",
		firstNameSpaces: "First name can't contain more than one space",
		imageSize: "Image size must be less than 10 MB",
		videoSize: "Video size must be less than 200 MB",
		invalidFormat: "Please select a valid image or video type",
		invalidWidth: "Image width must be more than 400 pixel",
		invalidHeight: "Image height must be more than 400 pixel",
		limitExceeded: "You can't select more than 20 files",
		invalidImages: "images are invalid",
		AreYouSureDelete: "Are you sure you want to delete ",
		deleteFile: "Delete File",
		fileSizeExceed: "File size can't exceed ",
		fileNotSupported: " files are not supported",
		maxLength: "Can't be more than",
		onlyChar: "Tilte must have at least 3 Letters least",
	},
	home: {
		exportExcel: "Export Excel",
	},
	header: {
		Search: "Search...",
		Editprofile: "Edit profile",
		SUPERADMIN: "SUPER admin",
		NOTIFICATIONS: "Notifications",
		Seeall: "See all",
	},
	errors: {
		Unauthorized: "Unauthorized",
		sorry401: "Sorry , your request could not be processed.",
		goToHome: "Go To Home page",
		sorry403:
			"We are sorry , but you do not have access to this page or resource.",
		Access: "Access Denied",
		notFound: "Page not found",
		error_text:
			" The page you’re looking for might have been removed, had its name changed or is temporarily unavailable.",
		serverError: "Internal server error",
	},
	MScreens: {
		LinkExpired: "Link expired",
		plaeaseEnterMail:
			"Please enter your email then click the button below to request another one.",
		email: "Email",
		mail_massage: "The input is not valid e-mail!",
		mail_req_massage: "Please input your e-mail!",
		ResendMail: "Resend mail",
		oops: "Oops!! This url Is Not Valid Anymore.",
		Ok: "OK",
		PasswordReset: "Passwords reset successfully",
		ResetPassword: "Rest new password",
		NewPassword: "New password",
		passwordMassage: "Please input your password!",
		passwordmin: "Password must be min 8 characters",
		passwordmax: "Password must be max 20 characters",
		passwordexpresion:
			"Password must be at least 8 characters including capital and small letters.",
		password_placeholder: "Enter your password",
		confirmPassword: "Confirm new password",
		confirm_massage: "Please confirm your password!",
		restPass_placeholder: "Confirm your password",
		cancle: "Cancel",
		resetSent: "Reset code was sent successfully",
		Reset: "Reset",
		CheckInpoxp1: "Please check your inbox or spam folder at",
		CheckInpoxp2: "for a link to reset your password .",
		backToSignin: "Back to sign in",
	},
	settings: {
		PasswordsChanged: "Passwords changed successfully",
		successfullyDeleted: "successfully deleted",
		ChangePassword: "change password",
		NewPassword: "New password",
		passwordMassage: "Please input your password!",
		passwordmin: "Password must be min 8 characters",
		passwordmax: "Password must be max 20 characters",
		passwordexpresion:
			"Password expresion that requires one lower case letter, one upper case letter, one digit",
		password_placeholder: "Enter your password",
		confirmPassword: "Confirm new password",
		confirm_massage: "Please confirm your password!",
		restPass_placeholder: "Confirm your password",
		logout: "logout",
		AccountDeactivated: "Account deactivated!",
		YourAccountDeactivated:
			"Your account have be deactivated, please be informed that you’ll never be able to login again or restore your account",
		YesSave: "Yes, Save",
		NoSave: "No, Don’t Save",
		Cancel: "Cancel",
		Delete: "Delete",
		SaveChanges: "Save changes",
		AreYouSoure: "Do you want to save this data ?",
		signIn: "Sign in",
		EnterPassFor: "Please enter your password for",
		password: "Password",
		passwordMassage: "Please input your password!",
		passwordmin: "Password must be min 8 characters",
		passwordmax: "Password must be max 20 characters",
		passwordexpresion:
			"Password expresion that requires one lower case letter, one upper case letter, one digit",
		password_placeholder: "Enter your password",
	},
	setting: {
		Settings: "Settings",
		Integratedappsmasterata: "Integrated apps master data",
		GoogleRECAPTCHA: "Google recaptcha",
		GoogleAnalyticsTrackingCode: "Google analytics tracking code",
		FacebookAppID: "Facebook app iD",
		FacebookAppSecret: "Facebook app secret",
		LinkedInAppID: "LinkedIn app iD",
		EmailedLinksSettings: "Emailed links settings",
		Emailverification: "Email verification",
		Enable: "Enable",
		Emailverificationlinkexpirytimeminutes:
			"Email verification link expiry time (minutes)",
		Mins: "Mins",
		EmailNotifications: "Email notifications",
		Resetpasslinkverificationlinkexpirytimeminutes:
			"Reset pass link verification link expiry time (minutes)",
		Resendmailinterval: "Resend mail interval",
		Tries: "Tries",
		Xplacholder: "xxxx-xxxx-xxxx",
	},
	Privileges: {
		AddRole: "Add Role",
		Newrole: "New Role",
		Personalinfo: "Personal info",
		Rolename: "Role name",
		ISACTIVE: "IS active",
		Hidetranslations: "Hide translations",
		Showtranslations: "Show translations",
		TranslateIntoAr: "Translate into “عربي“",
		TranslateIntoFr: "Translate into “française“",
		Enable: "Enable",
		Rolestatus: "Role status",
		Privileges: "Privileges",
		Privilege: "Privilege",
		AddPrivileges: "Add Privileges",
		SelectPrivileges: "Select Privileges",
		PleaseselectPrivilegestoAssign: "Please select Privileges to assign",
		AssignedPrivileges: "Assigned Privileges",
		Admins: "Admins",
		AddAdmins: "Add admins",
		SelectAdmins: "Select admins",
		Admin: "Admin",
		AddAdmin: "Add admin",
		SelectAdmin: "Select admin",
		PleaseselectAdminstoAssign: "Please select admins to assign",
		deletedSuccessfully: "Selected roles deleted successfully",
		editedSuccessfully: "Selected role edited successfully",
		addedSuccessfully: "Role added successfully",
	},
	currency: {
		Currencyname: "Currency name",
		Addcurrency: "Add currency",
		Newcurrency: "New currency",
		currencyinfo: "Currency info",
	},
	users: {
		USERS: "USERS",
		Name: "Name",
		Age: "Age",
		Email: "Email",
		Address: "Address",
		Admins: "Admins",
		UpdateAdmins: "Update Admins",
		Adduser: "Add admin",
		Personalinfo: "Personal info",
		Rules: "Roles",
		Addrule: "Add role",
		Selectrule: "Select role",
		PleaseselectruletoAssign: "Please select role to Assign",
		Assignedrules: "Assigned roles",
		deletedSuccessfully: "Selected users deleted successfully",
		editedSuccessfully: "Selected user edited successfully",
		addedSuccessfully: "User added successfully",
	},
	grid: {
		New: "New ",
		goTo: "Go to",
		gridSearch: "Search",
		reset: "Reset",
		sureToCancel: "Sure to cancel?",
		no: "No",
	},
	buttons: {
		Close: "Close",
		SaveNew: "Save & new",
		Save: "Save",
		requiredEdit: "Required Edit",
		Cancel: "Cancel",
		UpdateNew: "Update & new",
		savePublish: "Save & publish",
		saveForApproval: "Save for approval",
		Update: "Update",
		Duplicate: "Duplicate",
		Delete: "Delete",
		View: "View",
		Archive: "Archive",
		Edit: "Edit",
		confirm: "Are you sure you want to ",
		selectToEdit: "Please select one row to edit",
		selectToDelete: "Please select one row or more to delete",
		change: "Change",
		remove: "Remove",
		Send: "Send",
		done: "Done",
		clear: "Clear",
		qu: "?",
		selectAll: "Select all",
		deleteTheRest: "Delete the rest",
		add: "Add",
		YesCancel: "Yes, cancel",
		NoDontCancel: "No, Don’t cancel",
		rotate: "Rotate",
		crop: "Crop",
		open: "Open",
		saveDrafts: "Save as a draft",
		ok: "Ok",
		clone: "Clone",
		goBack: "Go Back",
	},
	inputs: {
		inputrequired: "Input required",
		PleaseinputyourFirstname: "Please input your first name!",
		PleaseinputyourMiddlename: "Please input your middle name!",
		PleaseinputyourLastName: "Please input your last name!",
		Pleaseinputyourphonenumber: "Please input your phone number!",
		Firstname: "First name",
		Middlename: "Middle name",
		LastName: "Last name",
		PhoneNumber: "Phone number",
		phonemin: "Phone can't be less than 8 numbers",
		phonemax: "Phone can't be more than 11 numbers",
		phone: "phone",
		Email: "E-mail",
		TheinputisnotvalidEmail: "The input is not valid e-mail!",
		PleaseinputyourEmail: "Please input your e-mail!",
		EmailAlreadyExists: "Email already exists",
		Firstnamemustcharacters: "Name must be min 3 characters",
		namecantacceptspace: "Name dosen't accept space",
		Namecanacceptspecialcharacters: "Name can't accept  special characters",
		Namecanacceptnumbers: "Name can't accept numbers",
		passwordDoesnotMatch: "Password and confirmation does not match",
	},
	masterData: {
		basicInfo: "Basic info",
		label: "Master data",
		add: "Add",
		name: "Name",
		email: "Email",
		value: "Value",
		isActive: "Is active",
		active: "Active",
		inActive: "Inactive",
		translate: "Translation",
		hideTranslations: "Hide translations",
		nameMaxLength: "Name length must not exceed 60 character",
		nameTranslationMaxLength:
			"Name translation max length must not exceed 60 character",
		nameMinLength: "Name minimum length is 3 characters",
		nameTranslationMinLength: "Name translation minimum length is 3 characters",
		namePlaceholder: "Enter name here",
		valuePlaceholder: "Enter value ex:'25'",
		nameTranslationPlaceholder: "Enter name translation here",
		deletedSuccessfully: "Selected data deleted successfully",
		editedSuccessfully: "Selected data edited successfully",
		addedSuccessfully: "Data added successfully",
		theFollowingData: "The following data ",
		inUseData: " are in use and can't be deleted",
		inUseActivated: " is in use and can't be deactivated",
	},
	countries: {
		basicInfo: "Basic info",
		label: "Country",
		deletedSuccessfully: "Selected countries deleted successfully",
		editedSuccessfully: "Selected country edited successfully",
		addedSuccessfully: "Country added successfully",
		nullFlag: "Please select an image",
		countryCurrency: "Country's currency",
		currencyPlaceholder: "Select country's currency",
		countryCodePlaceholder: "Enter country phone code, ex: +20",
		countryCode: "Country Code",
	},
	governorates: {
		basicInfo: "Basic info",
		label: "Governorate",
		deletedSuccessfully: "Selected governorates deleted successfully",
		editedSuccessfully: "Selected governorate edited successfully",
		addedSuccessfully: "Governorate added successfully",
	},
	schools: {
		SchoolName: "School name",
		Country: "Country",
		AddSchool: "Add school",
		State: "State",
		selectState: "Select a state",
		Fax: "Fax",
		AddressLine1: "Address line 1",
		AddressLine2: "Address line 2",
		PaymentMethod: "Payment method",
		Website: "Website",
		SchoolEmail: "School e-mail",
		AllowedStudents: "Allowed students",
		ContactPersonName: "Contact Person name",
		pleaseEngerName: "Please enter Your name",
		Position: "Position",
		enterPosition: "Please enter your position",
		email: "Email",
		enterEmail: "Please enter your email",
		Country: "Country",
		selectCountery: "Select a country",
		addContactPersonData: "Add contact",
		invalidURL: "Please enter a valid url",
		schoolData: "School data",
		contactPersonData: "Contact person Data",
		contactWorkPhone: "Work Phone",
		contactPersonalPhone: "Personal Phone",
		addContactModalTitle: "Add contact person",
	},
	sideBar: {
		Home: "Home",
		Courses: "Courses",
		Assignments: "Assignments",
		Exams: "Exams",
		Results: "Results",
		Schedule: "Schedule",
	},
	contactUs: {
		fullName: "Full name",
		email: "E-mail",
		RequestDetails: "Subject",
		PleaseinputyourRequestDetails: "Please input your Request Details",
		HereToHelp: "We’re here to help",
		ContactUS: "Contact us",
		ContactDetails: "Contact details",
		OpeningHours: "Opening hours",
		Closed: "Closed",
		About: "About",
		Howitworks: "How it works",
		ChargingStations: "Charging stations",
		RequestCard: "EV charging card",
		Additionallinks: "Additional links",
		AboutUs: "About us",
		subjectMin: "Subject length must be at least 20 characters",
		subjectMax: "Subject maximum length must be 200 characters",
		nameMin: "Name must be at least 2 characters",
		nameMax: "Name can't be more than 60 characters",
		dayopen: "Sunday - Thursday",
		dayclose: "Friday - Saturday",
	},
	notifications: {
		loadMore: "Load more notifications",
		notifications: "Notifications",
		noNotifications: "It's all good, you don't have new notifications",
		today: "Today's",
	},
	signIn: {
		WelcomeBack: "Welcome Back !",
		Tokeepconnected:
			"To keep connected with us please login with your personal info",
		SignIn: "Sign In",
		SignUp: "Sign Up",
		HelloFriend: "Hello, Friend!",
		personaldetails: "Enter your personal details and start journey with us",
		SignintoInfinity: "Sign in to infinity",
		Forgotpassword: " Forgot your password ?",
		haveaccount: " Don’t have an account?",
	},
	courses: {
		TodaysCourses: "Today's Courses",
		more: "More",
		Day: "Day",
		Week: "Week",
		Month: "Month",
		All: "All",
		ToDo: "ToDo",
		Completed: "Completed",
		Draft: "Draft",
		NoCoursesAdded: "No Courses Added",
		allCourses: "All Courses",
		noClassroomSelected: "No classrooms selected",
		coursesSubtitle: "Courses to complete today",
		attachments: "Attatchments",
		downloadAll: "Download All",
		maxLimit: "The maximum number of files has been reached",
		limitExceeded: `You can't upload more than 10 files`,
		courses: "Courses",
		successfullyEditedCourse: "Successfully edited a new course",
		attachmentRequired: "Please attach at least one file",
		successfullyAddedCourse: "Successfully added a new course",
		AssignedbyTeacher: "Assigned by Teacher",
		Target: "Target",
		Attachment: "Attachment",
		AllClasses: "All Classes",
		AuthorName: "Author Name",
		CourseDetails: "Course Details",
		Publishingdetails: "Publishing details",
		CourseSubject: "Course Subject",
		selectSubject: "Select Subject",
		CourseTitle: "Course Title",
		selectTitle: "Select Title",
		CourseDescriptions: "Course Descriptions",
		ReleaseDate: "Release Date",
		StudentsTarget: "Targeted Students",
		NoselectedTarget: "No selected Target",
		AddAttatchments: "Add Attatchments",
		newCourse: "Add Course",
		editCourse: "Edit Course",
		chapter: "Chapter",
		topics: "Topics",
		selectClass: "Select Class",
		selectSubject: "Select Subject",
		courseTitleRequired: "Course Title is required",
		releaseDateRequired: "Release Date is required",
		descriptionRequired: "Description is required",
	},
	messages: {
		Messages: "Messages",
		Message: "Message",
		NewMessage: "New Message",
		AllParents: "All Parents",
		Send: "Send",
		AllMessages: "All Messages",
		Unread: "Unread",
		Read: "Read",
		Attachments: "Attachments",
		Messageparent: "Message parent",
		writeyourmessagehere: "write your message here…",
		contactwithyour: "contact with your student’s parents become easy",
		sendInstant: "Send instant messages to parents",
		selectClassrooms: "Select Classrooms",
		selectStudents: "Select Students",
		selectSubject: "Select a subject",
	},
	Classes: {
		Students: "Students",
		Toolkit: "Toolkit",
		Homework: "Homework",
		Attendance: "Attendance",
		Select: "Select",
		empty: "contact with your student’s parents become easy ",
		Multiple: "Multiple",
		SelectMultiple: "Select Multiple",
		selectSubject: "Select Subject",
		set: "Set ",
		update: "Update ",
		delete: "Delete ",
		SetHomework: "Set Homework",
		UpdateHomework: "Update Homework",
		DeleteHomework: "Delete Homework",
		viewhomework: "view homework",
		Random: "Random",
		Timer: "Timer",
		inviteParents: "invite Parents",
		HelpingOther: "Helping Other",
		OnTask: "On Task",
		participating: "participating",
		TeamWork: "Team Work",
		WorkingHard: "Working Hard",
		Givefeedbackto: "Give feedback to ",
		Positive: "Positive",
		NeedsWork: "Needs Work",
		Saveattendance: "Save",
		Makeallpresent: "Make all present",
		MakeallAbsent: "Make all Absent",
		Classroom: "Classroom",
		ClassStory: "Class Story",
		Profile: "Profile",
		Messages: "Messages",
		selectAll: "Select All",
		to: "To:",
		MessageTo: "Message To :",
		search: "search",
		Cancel: "Cancel",
		Student: "Student",
		next: "Next",
		send: "Send",
		writethemessagetitle: "write the message title",
		writeyourmessagehere: "write your message here…",
		pleasewritethemessagetitle: "please write the message title",
		pleasewriteyourmessagehere: "please write your message",
		Wet: "Wet",
		Dry: "Dry",
		BM: "BM",
		WholeClass: "Whole Class",
		deleteHWconfirm:
			"Are you sure you want to delete these students homework ?",
		successfullyDeleted: "Successfully delete students homework",
		Note: "Note",
		Pleaseinputyourusername: "Please input your username!",
		Pleaseselectatarget: "Please select a target",
		badgeAssignedSuccessfully: "Badge assigned successfully",
		Badges: "Badges",
		Food: "Food",
		Diaper: "Diaper",
		sleep: "Sleep",
		Breakfast: "Breakfast",
		activities: "Activities",
		sickReport: "Sickness",
		None: "None",
		Some: "Some",
		All: "All",
		More: "More",
		Snack: "Snack",
		From: "From",
		to: "to",
		addMore: "add More",
		Lunch: "Lunch",
		writehere: "write here a note about this kid.. .",
		selecttime: "Select Time",
		successfullyAddedFood: "Successfully added a food report",
		successfullyAddedDiaper: "Successfully added a diaper report",
		successfullyAddedSleep: "Successfully added a sleeping report",
		successfullyAddedNote: "Successfully added a student note",
		successfullyAddedActivity: "Successfully added a new activity",
		successfullyAddedSickness: "Successfully added a sick report",
		deleteActivity: "Delete activity",
		deleteActivityConfirmation:
			"Are you sure you want to delete this activity ?",
		deleteSickness: "Delete sick report",
		deleteSicknessConfirmation:
			"Are you sure you want to delete this sick report ?",
		noteRequired: "Please fill note field",
		SetHomework2: " Set Homework",
		homeworktitle: "write homework title.",
		homeworkdetails: "write homework details here…. ",
		pleasehomeworktitle: "please write homework title",
		pleasehomeworkdetails: "please write homework details",
		Collapse: "Collapse",
		expand: "Expand",
		Pleaseinputyourusername: "Please input your username!",
		timeErrorMessage:
			"You have chosen a time in the future, please check (AM or PM) or choose a valid time",
		AreYouSureDelete: "Are you sure you want to delete ",
		deleteFile: "Delete File",
		fileSizeExceed: "File size can't exceed ",
		fileNotSupported: " files are not supported",
		homeWorkLength:
			"Homework length can not be more than 1000 character or less than 50 character",
		homeWorkTitleLength:
			"Homework title must be between 3 characters to 60 character",
	},
	filtration: {
		searchBy: "Search By",
	},
	filtrationBar: {
		filter: "Filter",
		subject: "Subject",
		date: "Date",
		courseName: "Course Name",
		subjectPlaceholder: "Select a subject",
		datePlaceholder: "Select a date",
		courseNamePlaceholder: "Type course name here...",
		allYear: "All Year",
		clear: "clear",
		ResultType: "Result Type",
		ResultEvaluation: "Result Evaluation",
	},
	assigments: {
		Assigments: "Assigments",
		Assignment: "Assignment",
		NoAssigmentsAdded: "No Assigments Added",
		Youhave: "You have ",
		NoFeedbackyet:
			"No Feedback yet , Complete your assignment first and wait your teacher's feedback",
		SuccessfullyPass: "Successfully Pass",
		assigmentstocompletetoday: " assigments to complete today",
		All: "All",
		ToDo: "To Do",
		Late: "Late",
		Feedback: "Feedback",
		Completed: "Completed",
		Uncomplet: "Uncomplet",
		open: "open",
		Feedback: "Feedback",
		ReSubmit: "Re-Submit",
		Teacherfeedback: "Teacher's feedback",
		TodayAssigments: "Today's Assigments",
		Applyyourassignmenthere: "Apply your assignment here..",
		AssignmentReply: "Assignment Reply",
		doesntmatchtherequirement: "doesn't match the requirement.",
	},
	exams: {
		exams: "Exams",
		headerSubtitle: "exams to complete today",
		all: "All",
		toDo: "To Do",
		upComing: "Up Coming",
		previous: "Previous",
		online: "Online",
		printed: "Printed",
		hours: "Hours",
		hour: "Hour",
		questions: "Questions",
		startExam: "Start Exam",
		instructions: "Instructions",
		daysLeft: "Days Left",
		Beforeyoubegin: "Before you begin",
		Themaximumtime:
			"The maximum time that allowed to Access the exam after the start time .",
		Theresnooption:
			"There's no option to pause. Make sure you will not be interrupted for",
		Ifyouaccidentallyclose:
			"If you accidentally close your browser, use the invitation link to get back to your test.",
		Hintyoucanuse:
			"Hint: you can use your own IDE and use copy-paste, but make sure your solution compiles in Scholigit Test environment.",
		HowtouseitInstruction: "How to use it : Instruction and Tips",
		minutes: "minutes",
		Hours: "Hours",
		StartTime: "Start Time",
		EndTime: "End Time",
		ExamDuration: "Exam Duration",
		NQuestions: "N. Questions",
		Questions: "Questions",
		Accessibilitytime: "Accessibility time",
		FromstartingTimeTill: "From starting Time Till",
		checkTipNumberbelow: "check Tip Number 1 below",
		noExamsFound: "No Exams Found",
		underCorrection: "Under Correction",
	},
	Results: {
		Results: "Results",
		withinthetotal: "within the total",
		Outoftotal: "Out of total",
		Excellent: "Excellent",
		VGood: "V.Good",
		Good: "Good",
		Congratulations: "Congratulations",
		successfullypassed:
			", You are successfully passed and transferred to the next Grade.",
		LatestResults: "Latest Results",
		Archive: "Archive",
		NoResultsfoundyet: "No Results found yet",
		Yourresultswillappearhere:
			"Your results will appear here once the correction is over",
		MoreDetails: "More Details",
	},
	timeline: {
		addPhoto: "Add Photo",
		addVideo: "Add Video",
		Photo: "Photo",
		Video: "Video",
		File: "File",
		post: "Post",
		TimeLine: "School Wall",
		Calender: "Calender",
		Attendens: "Attendens",
		TimeTable: "Time Table",
		HomeWork: "Homework",
		messages: "Messages",
		Exam: "Exams",
		classes: "Classes",
		subjects: "Subjects",
		onlineSessions: "Online Sessions",
		createTagline: "What’s happening in your classroom?",
		captionRequired: "Story caption is required",
		postCreatedSuccessfully: "Post created successfully",
		writeyourmessagehere: "write your message here…",
		WholeClass: "Whole Class",
		SetHomework: "Set Homework",
		Cancel: "Cancel",
		cropImage: "Crop selected image",
		crop: "Crop",
		imageSize: "Image size must be less than 10 MB",
		videoSize: "Video size must be less than 200 MB",
		invalidFormat: "Please select a valid image or video type",
		invalidWidth: "Image width must be more than 400 pixel",
		invalidHeight: "Image height must be more than 400 pixel",
		limitExceeded: "You can't select more than 4 files",
		invalidImages: "files are invalid",
		DropToUpload: "Drop files here to upload",
		Upload: "Upload",
	},
	attendance: {
		status: "Status",
		studentName: "Student Name",
		class: "Class",
		attendees: "Attendees",
		present: "Present",
		absent: "Absent",
		notes: "Notes",
		note: "Note",
		causeOfAbsence: "Cause of absence",
		downloadPDF: "Download PDF",
		presentALL: "Present All",
		absentAll: "Absent All",
		date: "Date",
		Save: "Save",
		Cancel: "Cancel",
		AddNote: "Add Note",
		recording: "Recording",
		files: "Files",
		onlineSession: "Online Session",
		chat: "Chat",
	},
	classroomStudents: {
		ShowAttend: "Show Attend",
		noDataMessage: "There are no students in this class",
		studentModalTitle: "Give feedback to ",
		WholeClass: "the whole class",
		badges: "Badges",
		attended: "Attended",
		absent: "Absent",
	},
	homeworkList: {
		date: "Start Date",
		edit: "Edit",
		titleClass: "Title",
		subject: "Subject",
		dueDate: "End Date ",
		solvedNo: "Student Status ",
		degree: "Degree ",
		details: "Details",
		correction: "Corrected",
		clone: "Clone homework",
		noDataMessage:
			"There are no homeworks for this day, please try another day",
		removeAll: " Remove All",
		add: "Add Class",
		search: "Search",
		startDate: "Start Date",
		endDate: "End Date",
		startEndDateMessage: "Please select a start and end date",
		classroomHasHomeworkMsg: "This classroom already has homework",
		delete: "Delete",
		colnedSuccess: "Homework has been Cloned Successfully",
		target: "Target",
		students: "Students",
		all: "All",
		classRoom: "Classroom",
		unsavedDatatitle: "Unsaved Data",
		unsavedDataMessage: "are you sure you want to ignore unsaved data",
		ignore: "Ignore",
		cancel: "Cancel",
		fetchSuccess: "Homework fetched successfully",
		cloneOnly: "Clone",
		correctionStatus: "Correction Status",
		ready: "Ready",
		readyToolTip: "Ready for Correction",
		done: "Done",
		unCompleted: "Uncompleted",
		notViewed: "Not Viewed",
	},
	homeworkBar: {
		homeWork: "HomeWork",
		addHomeWork: "Add HomeWork",
		fetch: "Fetch",
		title: "Title",
		needCorroction: "Need Correction",
	},

	homeworkStudentList: {
		Name: "Student Name",
		Status: "Question Status",
		NoQuestons: "No.Questions",
		NotCorrocted: "Not Corrected",
		Corrected: "Corrected",
		CorrectionStatus: "Correction Status",
		Degree: "Degree",
		Details: "Details",
		All: "All",
		UnCompleted: "Un Completed",
		Done: "Done",
		NotViewed: "Not Viewed",
		SolvingDuration: "Solving Duration",
		readyForCorrection: "Ready for Correction",
		Min: "Min",
		InProgress: "In Progress",
		ready: "Ready",
		notReady: "Not Ready",
	},
	classNavigation: {
		classRoom: "Class Room",
		classStory: "Class Story",
		messages: "Messages",
		specialNote: "Special Note",
		timeTable: "Timetable",
		homework: "Homework",
		attendance: "Attendance",
		exams: "Exams",
	},
	studentHomework: {
		mark: "Mark",
		addHomeWork: "Add HomeWork",
		correct: "Correct",
		corrected: "Correct",
		incorrect: " Incorrect",
		partialResult: " Partial Result",
		finish: "Finish",
		finisNext: "inish & Next Student",
		homeWork: "HomeWork",
	},
	quizQuestionsTypes: {
		True: "True",
		False: "False",
		paragraphPlaceholder: " Please Add your answer here  ",
		AddAnotherChoice: "Add Another Choice",
		Youmustfillallinputes: "You must fill all inputs",
		Youcantaddnewone: "You can't add another choice",
		Youmustmarkatleastonchoiceascorrectanswer:
			"You must mark at least one choice as correct answer",
	},
	studentQuiz: {
		quiz: "Quiz",
		selectContent: "Select Type",
		addAnotherQuestion: "Add Another Question",
		uploadfile: "Upload file",
		duplicate: "Duplicate",
		remove: "Remove",
		quizTitle: "Quiz Title",
		questionTitle: "Question Title",
		homeworkTitle: "Homework Title",
		multipleChoice: "Multiple Choice",
		singleChoice: "Single Choice",
		trueFulse: "True & False",
		truee: "True &",
		Falsee: " False",
		paragraph: "Paragraph",
		smallParagraph: "Small Paragraph",
		completeThefollowing: "Complete the following",
		reArrange: "Re - Arrange",
		Tagquestions: "Connect questions",
		StartDate: "Start Date",
		EndDate: "End Date",
		TotalDegree: "Total Degree",
		QuestionTitleRequired: "Question Title is Required",
		QuizModeRequired: "Question Mode is Required",
		homeworkRequired: "Homework Title is Required",
		SessionSubject: "Session Subject",
		QuizTitle: "Quiz Title",
		QuizTitleRequired: "Quiz title is required",
		option: "Option",
		startTimeRequired: "Start Time Required",
		dueDateRequired: "End Date Required",
		startDateRequired: "Start Date Required",
		dueTimeRequired: "Due Time Required",
		totalDegreeRequired: "Total Degree Required",
		titleRequired: "Title is required",
		whiteSpace: "Title can't start with or contain Only spaces",
		addQuestions: "Add Questions",
		sortQuestions: "Sort Questions",
		editQuestion: "Edit Question",
		removeQuiz: "Are you sure you want to remove all quiz",
		removeSelectedQuiz: "Are you sure you want to remove this question",
		deleteQuiz: "Delete Quiz",
		deleteQuestion: "Delete Question",
		SelectQuiz: "Please select quiz type",
		SelectQuestion: "Please select question type",
		DragQuestions: "Drag questions to reorder.",
		corroctAnswer: "Correct Answer",
		questionsHeaderRequired: "Question title is required",
		Question: "Question",
		choiceValidation: "You need to choose atleast one correct answer",
		connectQuestionsValidation:
			"You need to connect all questions with answers",
		TrainingMode: "Training Mode",
		ExamMode: "Exam Mode",
		MultipleMode: "Multiple Mode",
		generalMode: "General Mode",
		descriptionRequired: "Describtion is Required",
		homeworks: "Homeworks",
		description: "Description",
		deleteHomework: "Delete Homework",
		deleteHomeworkConfirm: "Are you sure you want to delete this homework ?",
		quizInDate: "Warning",
		removeQuizInDate:
			"There is another home work in this day, if you click delete you will lose your entered data",
		removeCurrentQuiz: "You will lose the current data",
		true: "True",
		false: "False",
		enterOption: "Enter options",
		uploadFile: "Upload File",
		recordAudio: "Record Audio",
		warning: "Warning",
		cancelEdit: "Are you sure you want to cancel editing ",
		cancelAdding: "Are you sure you want to cancel adding this question",
		yes: "Yes",
		no: "No, Keep Editing",
		accessMice: "You need to allow permission to use your microphone",
		deleteHomeworkSolvedConfirm:
			"there are one or more students already added answers to this homework",
		complete: "Complete",
		addOptions: "You need to add options",
		fillOptions: "You need to fill this option",
		cancel: "Cancel",
		option: "Option",
		addSections: "Add Sections",
		disabledAdd: "Can't add questions the exam is assigned",
	},
	connectQuestionType: {
		AddItem: "Add Item",
		Clear: "Clear",
		Edit: "Edit",
		Delete: "Delete",
		Done: "Done",
	},
	onlineSessionList: {
		ClassOnlineSession: "Class Online Session",
		Show: "Show",
		All: "All",
		Started: "Started",
		Scheduled: "Scheduled",
		Recorded: "Recorded",
		Ended: "Ended",
		CreateOnlineSession: "Create Online Session",
		Title: "Title",
		Subject: "Subject",
		Target: "Target",
		DateTime: "Date/Time",
		Status: "Status",
		Actions: "Actions",
		Join: "join / Start",
		Edit: "Edit",
		Summary: "Summary",
		details: "Details",
		Recordable: "Recordable",
		Delete: "Delete",
		EmptyMessage:
			"Sorry, there are no Online Sessions, please choose another option",
		NotApplicable: "Not Applicable",
		ConfirmationTitle: "Confirmation",
		ConfirmationContent: "Do you want to delete this session?",
		Attention: "Attention",
		SessionEnded: "Session has ended",
		SessionNotStarted: "Session has not started yet",
		RemainingTimeToStart: "Remaining Time To Start ",
		EndTime: "End Time",
		Hours: "hours",
		Days: "days",
		Minutes: "minutes",
		StartDate: "Start Date",
		EndDate: "End Date",
		SelectType: " Status ",
		Students: "Students",
		SearchStudents: "Search Students",
	},
	onlineSession: {
		createOnlineSession: "Create Online Session",
		titleRequired: "Title is required",
		subjectRequired: "Subject is required",
		chapterRequired: "Chapter is required",
		topicRequired: "Topics are required",
		classRoomRequired: "Class room is required",
		attachmentsRequired: "Attachments Required",
		StudentRequired: "You have to select student",
		title: "Title Of Onine Session",
		selectSubject: "Select Subject",
		dateRequired: "Date is Required",
		date: "Date",
		recording: "Recording",
		notRecording: "No Recording",
		endTimeRequired: "End Time is required",
		downlodable: "Downlodable",
		SendTo: "Send To..",
		class: "Class",
		student: "Student",
		removeall: "Remove all",
		startEndDate: "Start time can't be equal or greater than end time",
		selectClassRoom: "Select Class room",
		selectStudent: "Select Students",
		general: "General",
		course: "Course",
		noSelectedStudent: "There are no selected Student",
		selectGrade: "Select Grade",
		selectChapter: "Select chapter",
		selectTopics: "Select topics",
		createdSuccessfully: "Online session created successfully",
		updatedSuccessfully: "Online session updated successfully",
		minSession: "Session can't be less than 15 min",
		maxSession: "Session can't be more than 3 hours",
		spacesStart: "can't start with space",
		currentTimeValidation: "Session time must be an hour from now",
		haveToStudents: "You have to add students",
		maxStudent: "You have to select only 40 student or less",
		note: "Note : ",
		youInvited: "You invited",
		from: "From",
		maxStudent: "You have to select only 40 student or less",
	},
	calendar: {
		day: "Day",
		week: "Week",
		month: "Month",
		calendar: "Calendar",
		youHave: "You have",
		remainingTasks: "remaining tasks",
		timeLine: "Time Line",
		CreateEvent: "Create Event",
		CreateOnlineSession: "Create Online Session",
		Students: "Students",
		StartNow: "Start Now",
		Agenda: "Agenda",
	},
	calenderHeader: {
		Show: "Show",
		TimeTable: "Time Table",
		Event: "Event",
		ClassOnline: "Class Online",
		maxStudent: "You have to select only 40 student or less",
		note: "Note : ",
		youInvited: "You invited",
		from: "From",
	},
	addChaptersTopics: {
		AddChapter: "Add Chapter",
		ChapterName: "Chapter Name",
		ChapterNameRequired: "Chapter Name Required",
		TopicName: "Topic Name",
		TopicNameRequired: " Topic Name Required",
		AddTopic: "Add Topic",
		Cancel: "Cancel",
		Save: "Save",
		Ok: "Ok",
		Edit: "Edit",
		Delete: "Delete",
		ConfirmationMsg: "Are you sure you want to delete this Item?",
		Attention: "Attention",
		Semester: "Semester",
		SemesterRequired: " Semester Required ",
		Done: "Done",
		AddNewTopic: "Add New Topic",
		AddNewChapter: "Add New Chapter",
	},
	homeWorkToolbar: {
		Type: "Type",
		TotalDegree: "Total Degree",
		StartDate: "Start Date",
		DueDate: "Due Date",
		Target: "Target",
		HomeWork: "Homework",
		Quiz: "Quiz",
		CustomDate: "Custom Date",
		AllClass: "All Class",
		CustomStudents: "Custom Students",
		StudentSelected: "Student Selected",
		Save: "Save",
		Cancel: "Cancel",
		QuizTitle: "Quiz Title",
		QuizMode: "Quiz Mode",
		ExamMode: "Exam Mode",
		questionTitle: "Question title",
	},
	exam: {
		Exam: "Exam",
		Class: "Class",
		startExam: "Start Exam",
		continueExam: "Continue Exam",
		finishExam: "Finish Exam",
		next: "Next",
		previous: "Previous",
		FinishExam: "Finish",
		finishAndNext: "Finish & next student",
		solved: "Solved",
		NotSolved: "Not Solved",
		MarkForReview: "Mark For Review",
		minutes: "minutes",
		Hours: "Hours",
		StartTime: "Start Time",
		EndTime: "End Time",
		TotalResult: "Total Result",
		AutoCorrecred: "Auto Correcred",
		Written: "Paragraph",
		All: "All",
		ManualCorrection: "Manual Correction",
		Filterby: "Filter by",
		SwitchStudent: "Switch Student",
		ExamDuration: "Exam Duration",
		NQuestions: "N. Questions",
		Questions: "Questions",
		Accessibilitytime: "Accessibility time",
		FromstartingTimeTill: "From starting Time Till",
		checkTipNumberbelow: "check Tip Number 1 below",
		Beforeyoubegin: "Before you begin",
		Themaximumtime:
			"The maximum time that allowed to Access the exam after the start time .",
		Theresnooption:
			"There's no option to pause. Make sure you will not be interrupted for",
		Ifyouaccidentallyclose:
			"If you accidentally close your browser, use the invitation link to get back to your test.",
		Hintyoucanuse:
			"Hint: you can use your own IDE and use copy-paste, but make sure your solution compiles in Scholigit Test environment.",
		HowtouseitInstruction: "How to use it : Instruction and Tips",
		Areyouready: "Are you ready ?",
		Theexamisnotvalidanymore: "The exam is not valid anymore",
		ThisexamURLisnotvalidanymore:
			"Oops! This exam URL is not valid anymore, It is for one use only",
		GoToExamsPage: "Go To Exams Page",
		question: "Question",
		point: "Point",
		MultiChoise: "Multi Choise",
		SingleChoise: "Single Choise",
		Choise: "Choose",
		Trueorfalse: "True or false",
		TOF: "T.o.F",
		true: "True",
		false: "False",
		connect: "Connect",
		rearrange: "Re-arrange",
		complete: "Complete",
		wellDone: "Well Done",
		youDidGreat: "You did great",
		yourResult: "Your result",
		done: "Done",
		examSubmitted: "Your Answers have been submitted",
		waitForCorrection:
			"Wait for the correction to finish for the exam and you will be notified of the result soon upon completion",
		keepInMind:
			"Keep in mind that when you click on 'Finish' , that's mean that you have finished your exam and can't refer to your answers again.",
		TextAreaTypeAnswer: "Type your answer here...",
		marks: "Marks",
		outOf: "out of",
		switch: "Switch",
		Correct: "Correct",
		Incorrect: "Incorrect",
		PartialResult: "Partial Result",
		switchStudentParagraph:
			"You're about switching to another student, are you sure?",
		All: "All",
		studentTotalDegree: "Total Result for this student is ",
		confirmResult: ", confirm this result as a final result",
		qLetter: "Q",
	},
	examList: {
		Subject: "Subject",
		Degree: "Degree",
		Duration: "Duration",
		Questions: "Questions",
		ExamType: "Exam Type",
		Status: "Status",
		WaitForApprove: "Wait For Approval",
		InProgress: "In Progress",
		NotViewed: "Not Viewed",
		Rejected: "Requires Edit",
		AssignedTo: "Assigned To",
		minutes: "Hours",
		Montley: "Monthly",
		Midterm: "Midterm",
		Final: "Final",
		training: "Training",
		Correctors: "Correctors",
		SwitchToTraining: "Switch To Training",
		Delete: "Delete",
		Edit: "Edit",
		CorrectorsAdded: "Correctors Added",
		RequiredCorrectors: "Required Correctors",
		NoTeachersForCorrection: "No teacher were even placed for correction",
		ThereAre: "There are",
		Teacherstocorrecttheexam: "teachers to correct the exam",
		Exams: "Exams",
		CreateExam: "Create Exam",
		myExams: "My Exams",
		assigned: "Assigned",
		correction: "Correction",
		previous: "Previous",
		done: "Done",
		published: "Published",
		approved: "Approved",
		examTitle: "Exam Title",
		fromDate: "From Date",
		toDate: "To Date",
		emptyState: "Unfortunately, there is no data",
		draft: "Draft",
	},
	fetchHomework: {
		to: "To",
		fromDate: "From Date",
		endDate: "End Date",
		homework: "Homework",
		selectClass: "Please select a class",
		myHomework: "My Homework",
		othersHomeWork: "Other's Homework",
		type: "Type",
		degree: "Degree",
		date: "Date",
		target: "Target",
		quiz: "Quiz",
		teacher: "Teacher",
		from: "from",
		emptyState: "There are no homeworks to Fetch",
	},
	createExam: {
		createExam: "Create Exam",
		examTitle: "Exam Title",
		examTime: "Exam Time",
		monthelyExam: "Monthely Exam",
		timeDuratuion: "Time Duratuion",
		fixed: "Fixed",
		day: "Day",
		range: "Range",
		addClasses: "Add Classes",
		grade: "Grade",
		targetBy: "Target ",
		classes: " Classes",
		finalExam: "Final Exam",
		midtermExam: "Midterm Exam",
		monthlyExam: "Monthly Exam",
		finalTrainingExam: "Final Training Exam",
		midtermTrainingExam: "Midterm Training Exam",
		monthlyTrainingExam: "Monthly Training Exam",
		teacher: "Teacher",
		randomSort: "Random",
		sameSort: "Sort",
		correctors: "Correctors",
		selectcorrectorsMode: "Please Select Correctors Mode",
		correctorsMode: "Correctors Mode",
		questions: "Questions",
		percentage: "Percentage",
		done: "Done",
		removeAll: "Remove All",
		addTeacher: "Add Teacher",
		assignTeacher: "Assign To Teacher",
		teacherSelected: "Teacher Selected",
		selectTeacher: "Select Teacher",
		resetAll: "Reset All",
		percentageError: "Total percentage must be 100%",
		confirmDataLoss: "Corroctors Confirmation",
		confirmMessage: "Are you sure want To Convert Corroctors Mode?",
		Assigned: "Assigned",
		AssignedToYou: "Assigned to You",
		inprogress: "In Progress",
		waitingForApproval: "Waiting for approval",
		Approved: "Approved",
		RequiredEdit: "Required edit",
		NotAssigned: "Not assigned",
		assignedTo: "Assigned To",
		requiredEdit: "Required Edit",
		RequiredEditReason: "Required Edit Reason",
		Convert: "Convert",
	},
	superCorList: {
		Exam: "Exam",
		Exams: "Exams",
		Date: "Date",
		Time: "Time",
		Students: "Students ",
		Correctors: "Correctors",
		Duration: "Duration",
		NoOfQuestions: "No. Of Questions",
		Correction: "Correction",
		Assigned: "Assigned",
		Teacher: " Teacher",
		All: "All ",
		Summary: "Summary",
		Question: "Question",
		Progress: "Progress",
		DonePercentage: "Done Percentage",
		LastTimeCorrection: "Last Time Correction",
		Status: "Status",
		Number: "Number",
		AutoCorrected: "Auto-Corrected",
		ManualQuestion: "Manual Question",
		Grade: "Grade",
		Done: "Done",
		NotViewed: "Not Viewed",
		InProgress: "In Progress",
		ModifyCorrectors: "Modify Correctors",
		Hours: "Hours",
		AssignedTeachers: "Assigned Teachers",
		AssignedExams: "Assigned Exams",
		NotCorrected: "Not Corrected",
	},
};
