import React, { Component } from "react";
import { Provider } from "react-redux";
import "./App.css";
import "antd/dist/antd.css";
import "./styelSheets/css/style.min.css";
import store from "./store";
import Routes from "./routes/index";
import { setLocale } from "./modules/Common/store/actions";
import { authCheck, authLogin } from "./modules/Authintication/store/actions";
import CacheReset from "./CacheReset";
import LoadingIndicator from "./modules/Common/components/LoadingIndicator/LoadingIndicator";
import { loginAs } from "./modules/Teacher/services";

store.dispatch(authCheck());
store.dispatch(
	setLocale(
		localStorage.getItem("currentLocale") !== null
			? localStorage.getItem("currentLocale")
			: "english"
	)
);

class App extends Component {
	state = {
		loading: true,
	};

	componentDidMount() {
		if ("scrollRestoration" in window.history) {
			window.history.scrollRestoration = "manual";
		}
		if (this.parseQuerstring(window.location.search.substr(1)).id) {
			sessionStorage.setItem(
				"access_token",
				this.parseQuerstring(window.location.search.substr(1)).token
			);
			this.loginAsTeacher();
		} else {
			this.setState({
				loading: false,
			});
		}
	}

	parseQuerstring(search) {
		var obj = {};
		search.split("&").forEach((a) => {
			let [key, val] = a.split("=");
			obj = { ...obj, [key]: val };
		});
		return obj;
	}

	loginAsTeacher = () => {
		let body = {
			username: this.parseQuerstring(window.location.search.substr(1)).id,
			password: null,
			clientId: "FoundationAPI",
			clientSecret: "FoundationSecretKey",
		};
		loginAs(
			body,
			(res) => {
				store.dispatch(
					authLogin({
						token: res.data.accessToken,
						roles:
							res.data.roles.length > 0
								? res.data.roles
								: [
										"Home",
										"Courses",
										"Assignments",
										"Exams",
										"Results",
										"Schedule",
										"Settings",
								  ],
						refreshToken: res.data.refreshToken,
						token_expiry: res.data.tokenExpiration,
						rememberMe: false,
						user: { name: res.data.name, image: res.data.image },
						userType: res.data.userType,
					})
				);
				let user = {
					name: res.data.name,
					image: res.data.image,
				};
				window.sessionStorage.setItem("user", JSON.stringify(user));
				this.setState({ loading: false });
				window.location.replace("/");
			},
			(errors) => {
				sessionStorage.removeItem("access_token");
				this.setState({
					loading: false,
				});
			}
		);
	};

	render() {
		return this.state.loading ? (
			<LoadingIndicator />
		) : (
			<CacheReset>
				{({ loading, isLatestVersion, refreshCacheAndReload }) => {
					if (loading) return <LoadingIndicator />;
					if (!loading && !isLatestVersion) {
						refreshCacheAndReload();
					}
					return (
						<Provider store={store}>
							<Routes />
						</Provider>
					);
				}}
			</CacheReset>
			
		);
	}
}

export default App;
