/* eslint-disable eqeqeq */
import * as signalR from "@microsoft/signalr"
  
import { SET_ACTIVE_MESSAGE } from '../../modules/Common/store/action-types';
import { appendNewMessage } from '../../modules/Common/store/actions';
  
var connection = null;

const startSignalRConnection = (connection) => connection.start()
    .then(() => {
		console.info('SignalR Connected')
	})
    .catch(err => {
		console.error('SignalR Connection Error: ', err)
	});
  
const signalRMiddleware = store => next => async (action) => {
    // register signalR after the user logged in
	 if (action.type === SET_ACTIVE_MESSAGE) {
    	const connectionHub = 'https://chatservice.scholigit.com/ChatHub?TicketID=' + action.payload.id;
  
		const protocol = new signalR.JsonHubProtocol()

		// let transport to fall back to to LongPolling if it needs to
		const transport = signalR.HttpTransportType.LongPolling;
		
		// Add token to headers
		var token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : window.sessionStorage.getItem('access_token')
		
		const options = {
			transport,
			logMessageContent: false,
			logger: signalR.LogLevel.None,
			accessTokenFactory: () => {
				return token
			}
		};
  
		// create the connection instance
		connection = new signalR.HubConnectionBuilder()
			.withUrl(connectionHub, options)
			.withHubProtocol(protocol)
			.build();
  
		// re-establish the connection if connection dropped
		connection.onclose(() => setTimeout(startSignalRConnection(connection), 5000));
  
		startSignalRConnection(connection);
	}


	if(connection){
		connection.on('ReceiveMessage', (res) => {
			store.dispatch(appendNewMessage({
				ticketID: action.payload.id,
				message: res
			}))
		})
	}
  
    return next(action);
};
  
export default signalRMiddleware;