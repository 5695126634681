import { getResource, postResource, testPostResource ,testGetResource} from "../../network";

/**
 * Get a list of teacher assigned classrooms
 * @param {Function} onSuccess
 * @param {Function} onError
 */
export function listClassRooms(onSuccess, onError) {
	getResource(
		"/scholigit/api/Teachers/GetWebTeacherClassRooms",
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function listClassRoomSubjects(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/Teachers/GetTeacherSubjects?ClassRoomId=${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getClassRoomReportTabs(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/SchoolConfigurations/GetSchoolReportsByClassRoom?ClassRoomID=${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function listClassRoomStudentsBySubject(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/Teachers/GetClassRoomStudents?ClassRoomId=${query.classRoomID}&SubjectId=${query.subjectID}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getStudentTickets(query, onSuccess, onError) {
	getResource(
		`/chatservice/api/Tickets/GetTickets?filter=${query.filter}&Count=${query.count}&LastDateTicks=${query.lastDateTicks}&SearchString=${query.SearchString}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getTicketsByRelatedUsers(body, onSuccess, onError) {
	postResource(
		`/chatservice/api/Tickets/GetTicketsByRelatedUsers`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getTicketThread(query, onSuccess, onError) {
	getResource(
		`/chatservice/api/Tickets/GetTicketThreadv2?TicketId=${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getStudentsParents(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/Teachers/GetClassRoomStudentsParents?ClassRoomId=${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function appendNewTicket(body, onSuccess, onError) {
	postResource(
		"/chatservice/api/Tickets/CreateThreadV2",
		body,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		},
		true
	);
}

export function createNewReply(body, onSuccess, onError) {
	postResource(
		"/chatservice/api/Tickets/ReplyV2",
		body,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		},
		true
	);
}

export function setAsSeen(id, onSuccess, onError) {
	getResource(
		`/chatservice/api/Tickets/SetAsSeen?TicketId=${id}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

/**Class timeline CRUD */

export function appendStory(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/TimeLine/AddTimeLine",
		body,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		},
		true
	);
}

export function storiesList(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/TimeLine/GetTeacherTimeLine?ClassRoomId=${
			query.ClassRoomId
		}&Count=${query.Count}&${
			!!query.lastDateTicks && "lastDateTicks=" + query.lastDateTicks
		}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function schoolWallList(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/News/GetNews?Count=${query.Count}&lastDateTicks=${query.lastDateTicks}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function deletePost(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/TimeLine/RemoveTimeLine",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.error);
		}
	);
}

/** Homework CRUD */

export function assignHomework(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Homeworks/InsertHomeWork",
		body,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		},
		true
	);
}

export function removeHomework(body, forceDelete, onSuccess, onError) {
	postResource(
		`/scholigit/api/Homeworks/RemoveHomeWork?forceDelete=${forceDelete}`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getHomeworkDetails(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/Homeworks/GetTeacherHomeworkDetails${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function updateHomework(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Homeworks/EditHomeWork",
		body,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		},
		true
	);
}

export function toggleAttendance(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Attendances/AddAttendance",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getUserInfo(onSuccess, onError) {
	getResource(
		`/api/Account/GetUserInfo`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function updateUserInfo(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Parent/UpdateProfileInfo",
		body,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail);
		},
		true
	);
}

export function changePassword(body, onSuccess, onError) {
	postResource(
		"/api/Account/ChangePassword",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function listAllBadges(onSuccess, onError) {
	getResource(
		"/scholigit/api/Badges/GetBadgesDropDown",
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

/**Student Reports Request */

export function assignBadge(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Badges/AssignMultipleBadge",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function assignActivity(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/StudentsReports/AddStudentsActivityReport",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function setSicknessReport(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/StudentsReports/AddStudentsSickReport",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function assignMealStatus(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/StudentsReports/AddStudentsFoodReport",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function assignSleepingStatus(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/StudentsReports/AddStudentsSleepReport",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function updateSleepingStatus(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/StudentsReports/EditStudentSleepReport",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function assignDiaperStatus(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/StudentsReports/AddStudentsDiaperReport",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function updateDiaperStatus(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/StudentsReports/EditStudentDiaperReport",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function assignNotes(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/StudentsReports/AddStudentsNoteReport",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getBadgesReport(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/Teachers/GetStudentBadges?${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getActivities(id, onSuccess, onError) {
	getResource(
		`/scholigit/api/StudentsReports/GetStudentActivytReport?StudentID=${id}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getSicknessReports(id, onSuccess, onError) {
	getResource(
		`/scholigit/api/StudentsReports/GetStudentSickReport?StudentID=${id}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getFoodReport(id, onSuccess, onError) {
	getResource(
		`/scholigit/api/StudentsReports/GetStudentFoodReport?StudentID=${id}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getDiaperReport(id, onSuccess, onError) {
	getResource(
		`/scholigit/api/StudentsReports/GetStudentDiaperReport?StudentID=${id}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getSleepingReport(id, onSuccess, onError) {
	getResource(
		`/scholigit/api/StudentsReports/GetStudentSleepReport?StudentID=${id}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getNotes(id, onSuccess, onError) {
	getResource(
		`/scholigit/api/StudentsReports/GetStudentNoteReport?StudentID=${id}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

/**MISC */

export function badgesMasterdata(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/StudentsReports/GetSchoolMasterData?Type=${query.Type}&EducationalStageID=${query.EducationalStageID}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getSchoolDetails(onSuccess, onError) {
	getResource(
		"/scholigit/api/School/GetAdminSchoolDetails",
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function listAllNotifications(body, onSuccess, onError) {
	postResource(
		"/notification/api/Notification/GetUserNotifications",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function setDeviceToken(body, onSuccess, onError) {
	postResource(
		`/api/Auth/AddDeviceTOken?Token=${body.Token}`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getExamsList(body, onSuccess, onError) {
	postResource(
		"/api/Exams/GetExamsForDemo",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		},
		false,
		true
	);
}

export function getTeacherExamsList(body, onSuccess, onError) {
	postResource(
		"/examapi/api/Exams/GetCorrectorExams",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(errors) => {
			onError(errors);
		}
	);
}

export function getSubjectsDropdown(onSuccess, onError) {
	getResource(
		"/scholigit/api/Subject/GetSubjectsDropDown",
		(res) => {
			onSuccess(res);
		},
		(errors) => {
			onError(errors);
		}
	);
}

export function getDivisions(onSuccess, onError) {
	getResource(
		"/scholigit/api/Division/GetDivisionsDropDown",
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getSchoolDivisions(onSuccess, onError) {
	getResource(
		"/scholigit/api/SchoolConfigurations/GetSchoolDivisionsDropDown",
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getDivisionLevels(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/EducationalStage/GetEducationalStagesDropDown?DivisionID=${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getLevelGrades(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/EducationalGrade/GetEducationalGradesDropDown?EducationalStageID=${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getGradeClassrooms(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/ClassRoom/GetClassRoomsByEducationalGrade?EducationalGradeID=${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function GetTeacherExamInfo(query, onSuccess, onError) {
	getResource(
		`/examapi/api/Exams/GetTeacherExamInfo?ExamID=${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getCorrectorExamSession(query, onSuccess, onError) {
	getResource(
		`/examapi/api/Sessions/GetCorrectorExamSession?ExamSessionID=${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function correctSessionQuestion(body, onSuccess, onError) {
	postResource(
		"/examapi/api/Sessions/CorrectSessionQuestion",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(errors) => {
			onError(errors);
		}
	);
}

export function listAllStudents(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Student/GetStudentsDropDown",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getChapterTopics(query, onSuccess, onError) {
	getResource(
		"/scholigit/api/Topics/List?ChapterID=" + query,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getSubjectChapters(query, onSuccess, onError) {
	let url = query.semesterID
		? "/scholigit/api/Chapters/List?SubjectID=" +
		  query.subjectID +
		  "&SemesterID=" +
		  query.semesterID
		: "/scholigit/api/Chapters/List?SubjectID=" + query.subjectID;
	getResource(
		url,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function currentSemesterSubjectChapters(query, onSuccess, onError) {
	getResource(
		"/scholigit/api/Chapters/GetCurrentSemeseterSubjectChapters?SubjectID=" +
			query.subjectID,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function editClassSpecialNote(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/SpecialNote/UpdateSpecialNotes",
		body,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		},
		true
	);
}

export function getClassSpecailNote(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/SpecialNote/GetSpecialNotes",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getAttendanceList(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/Attendances/GetClassRoomAttendances?ClassRoomID=${query.classRoomID}&SubjectID=${query.subjectID}&date=${query.date}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function insertClassHomeWork(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Homeworks/InsertHomeWork",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail);
		}
	);
}

export function editClassHomeWork(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Homeworks/EditHomeWork",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getHomeworkDetailsByDate(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/Homeworks/GetTeacherHomeworkDetailsForEdit?ClassRoomId=${query.classRoom}&Date=${query.date}&SubjectId=${query.subjectID}&HomeworkID=${query.HomeworkID}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail);
		}
	);
}

export function getClassRoomHomeworkDetailsByDate(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/Homeworks/GetClassRoomHomeworkDetailsForEdit?ClassRoomId=${query.classRoom}&Date=${query.date}&SubjectId=${query.subjectID}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail);
		}
	);
}

export function getClassroomHomework(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/Homeworks/GetClassRoomHomeworks?ClassRoom=${query.classRoom}&FromDate=${query.FromDate}&ToDate=${query.ToDate}&subjectId=${query.subjectID}&title=${query.title}&ReadyForCorrectionOnly=${query.readyForCorrectionOnly}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getHomeworkSessions(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/HomeworkSessions/GetHomeworkSessionsForTeacher",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function correctHomeworkQuestion(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/HomeworkSessions/CorrectHomeworkSessionQuestion",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getHomeworkSessionDetails(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/HomeworkSessions/GetSessionDetailsForTeacher",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function createOnlineSession(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/OnlineSessions/Create",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail);
		}
	);
}

export function GetSystemSubjectsDropDown(onSuccess, onError) {
	getResource(
		`/scholigit/api/Subject/GetSystemSubjectsDropDown`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getTeacherCalendarList(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Teachers/GetTeacherCalender",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getOnlineSessionsList(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/OnlineSessions/GetTeacherOnlineSessions",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}
export function joinOnlineSession(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/OnlineSessions/Join"
		/*"/api/OnlineSessions/Join"*/,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

// export function getSubjectsDropdownByStageId(query, onSuccess, onError) {
//     getResource(`/scholigit/api/Subject/GetSubjectsDropDown?EducationalGradeID=${query}`, (res) => {
//         onSuccess(res)
//     }, (errors) => {
//         onError(errors)
//     })
// }

export function getSubjectsDropdownByStageId(query, onSuccess, onError) {
	let url = query.semesterID
		? `/scholigit/api/Subject/GetTeacherSubjects?EducationalGradeID=${query.subjectID}&SemeseterID=${query.semesterID}`
		: query.subjectID
		? `/scholigit/api/Subject/GetTeacherSubjects?EducationalGradeID=${query.subjectID}`
		: "/scholigit/api/Subject/GetTeacherSubjects";
	getResource(
		url,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getClassRoomBySubjectId(query, onSuccess, onError) {
	let url = query
		? `/scholigit/api/ClassRoom/GetTeacherClassRooms?SubjectID=${query}`
		: `/scholigit/api/ClassRoom/GetTeacherClassRooms`;
	getResource(
		url,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getOnlineSessionForUpdate(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/OnlineSessions/GetOnlineSessionForUpdate?SessionID=${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function deleteOnlineSession(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/OnlineSessions/Delete",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function updateOnlineSession(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/OnlineSessions/Update",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

//chapters CRUD :2 Add Chapter
export function addChapterToList(body, onSuccess, onError) {
	postResource(
		`/scholigit/api/Chapters/CreateChapter`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

//chapters CRUD :3-Update Chapter
export function updateChapterInList(body, onSuccess, onError) {
	postResource(
		`/scholigit/api/Chapters/Update`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

//chapters CRUD :4-Delete Chapter
export function removeChapterFromList(body, onSuccess, onError) {
	postResource(
		`/scholigit/api/Chapters/Delete`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

//Topics CRUD :2 Add Topic
export function addTopicToList(body, onSuccess, onError) {
	postResource(
		`/scholigit/api/Topics/Create`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

//Topics CRUD :3-Update Topic
export function updateTopicInList(body, onSuccess, onError) {
	postResource(
		`/scholigit/api/Topics/Update`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

//Topics CRUD :4-Delete Topic
export function removeTopicFromList(body, onSuccess, onError) {
	postResource(
		`/scholigit/api/Topics/Delete`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getClassRoomSemesters(query, gradeID, onSuccess, onError) {
	let url = query
		? "/scholigit/api/SemestersConfigurations/GetConfiguredSemesetersDropDown?ClassRoomID=" +
		  query
		: "/scholigit/api/SemestersConfigurations/GetConfiguredSemesetersDropDown?GradeID=" +
		  gradeID;
	getResource(
		url,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail);
		}
	);
}

export function getCourseDetails(query, onSuccess, onError) {
	getResource(
		"/scholigit/api/Materials/GetMaterialDetails?MaterialID=" + query,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getSchoolCourses(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Materials/GetTeacherMaterials",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getSubjects(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/Subject/GetSubjectsDropDown?EducationalGradeID=${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getClassroomSubjects(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/Subject/GetTeacherSubjects?ClassRoomID=${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function removeCourse(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Materials/DeleteMaterial",
		body,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		},
		true
	);
}

export function editSchoolCourse(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Materials/EditMaterial",
		body,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		},
		true,
		true
	);
}

export function addSchoolCourse(body, onSuccess, onError) {
	postResource(
		"/scholigit/api/Materials/AddMaterial",
		body,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		},
		true,
		true
	);
}

export function getTeachersDropDown(onSuccess, onError) {
	getResource(
		"/scholigit/api/Teachers/GetTeachersDropDown",
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function loginAs(body, onSuccess, onError) {
	postResource(
		"/api/Auth/LoginAs",
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getHomeworkDetailsForEdit(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/Homeworks/GetTeacherHomeworkDetailsForEdit?HomeworkID=${query.HomeworkID}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getHomeworkDetailsByStartDate(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/Homeworks/GetTeacherHomeworkDetailsForEdit?ClassRoomId=${query.classRoom}&Date=${query.date}&SubjectId=${query.subjectID}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getTeacherAssignedClassRooms(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/Teachers/GetAssignedClassRooms?&SubjectID=${query.subjectID}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getSupervisorAssignedClassRooms(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/ClassRoom/GetAssignedClassRooms?&SubjectID=${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function cloneHomework(body, onSuccess, onError) {
	postResource(
		`/scholigit/api/Homeworks/CloneHomework`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getClassHomeworksForFetch(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/Homeworks/GetClassRoomHomeworksForFetch?${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getTeachersDropDownBySubject(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/Teachers/GetTeachersDropDownBySubject?&SubjectID=${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}
export function getTeacherExams(body, onSuccess, onError) {
	postResource(
		`/examapi/api/Exams/GetTeacherExams`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getTeacherAssignedExams(body, onSuccess, onError) {
	postResource(
		`/examapi/api/Exams/GetTeacherAssingedExams`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getCorrectorExams(body, onSuccess, onError) {
	postResource(
		`/examapi/api/Exams/GetCorrectorExams`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function switchExamToTrainingMode(query, onSuccess, onError) {
	postResource(
		`/examapi/api/ExamBuilder/SwitchExamToTrainingMode?ExamID=${query}`,
		JSON.stringify({}),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function deleteExam(body, onSuccess, onError) {
	postResource(
		`/examapi/api/ExamBuilder/DeleteExam`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getSubjectsDropdownByIsSupervisor(query, onSuccess, onError) {
	let url = `/scholigit/api/Subject/GetTeacherSubjects?includeSupervisionSubjects=true`;
	getResource(
		url,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function GetTeacherAssingedClassRoomsBySubject(
	query,
	onSuccess,
	onError
) {
	let url = `/scholigit/api/Classroom/GetTeacherAssingedClassRoomsBySubject?SubjectID=${query}`;
	getResource(
		url,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function createFixedExam(body, onSuccess, onError) {
	postResource(
		`/examapi/api/ExamBuilder/CreateFixedExam`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function updateFixedExam(body, onSuccess, onError) {
	postResource(
		`/examapi/api/ExamBuilder/UpdateFixedExam`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function updateApprovalStatusForFixedExam(body, onSuccess, onError) {
	postResource(
		`/examapi/api/ExamBuilder/UpdateApprovalStatusForFixedExam`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getExamForEdit(query, onSuccess, onError) {
	getResource(
		`/examapi/api/ExamBuilder/GetFixedExamForEdit?ExamID=${query}`,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getExamCorrectorsEdit(query, onSuccess, onError) {
	postResource(
		`/examapi/api/ExamBuilder/GetExamCorrectorsForEdit?ExamID=${query}`,
		JSON.stringify({}),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function updateCorrectorsEdit(body, onSuccess, onError) {
	postResource(
		`/examapi/api/ExamBuilder/UpdateCorrectors`,
		JSON.stringify(body),
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}

export function getOnlineSessionSummery(query, onSuccess, onError) {
	getResource(
		`/scholigit/api/OnlineSessions/GetOnlineSessionSummery?SessionID=${query}`
		/*`api/OnlineSessions/GetOnlineSessionSummery?SessionID=${query}`*/,
		(res) => {
			onSuccess(res);
		},
		(fail) => {
			onError(fail.errors);
		}
	);
}
