
import {
    SET_SPECIAL_NOTE_VISIBILITY,
    LIST_CLASSROOMS,
    LIST_STORIES,
    ADD_STORY,
    TOGGLE_FLAG,
    SET_ALLOWED_HOMEWORK_ACTIONS,
    LIST_CLASS_STUDENTS,
    UPDATE_STUDENTS,
    SET_FIREBASE_TOKEN,
    LIST_ALL_NOTIFICATIONS,
    SET_SECTION_TITLE,
    SET_ACTIVE_SUBJECT, 
    SET_QUIZ_TYPE, 
    SET_STUDENTS_LIST,
    SET_CLASSROOMS_LIST,
    APPEND_INPUT,
    REMOVE_INPUT,
    REMOVE_ALL_INPUTS,
    EDIT_INPUT_VALUE,
    SET_ACTIVE_EDITOR,
    SET_ACTIVE_NAVIGATION_TAB,
    SET_CURRENT_CLASS,
    SET_CURRENT_QUIZ,
    REMOVE_LAST_INPUT,
    SET_QUESTION_TITLE, 
} from './action-types'


/**
 * Creates an action with a specified action type and payload
 * @param {*} payload 
 */
export function setSpecialNoteVisibility(payload) {
    return {
        type: SET_SPECIAL_NOTE_VISIBILITY,
        payload
    }
}

export function classRoomsList(payload) {
    return {
        type: LIST_CLASSROOMS,
        payload
    }
}

export function listStories(payload) {
    return {
        type: LIST_STORIES,
        payload
    }
}

export function addStory(payload) {
    return {
        type: ADD_STORY,
        payload
    }
}

export function toggleFlag(payload) {
    return {
        type: TOGGLE_FLAG,
        payload
    }
}

export function setAllowedHomeworkActions(payload) {
    return {
        type: SET_ALLOWED_HOMEWORK_ACTIONS,
        payload
    }
}

export function listClassStudents(payload) {
    return {
        type: LIST_CLASS_STUDENTS,
        payload
    }
}

export function updateStudents(payload) {
    return {
        type: UPDATE_STUDENTS,
        payload
    }
}

export function setFirebaseToken(payload) {
    return {
        type: SET_FIREBASE_TOKEN,
        payload
    }
}

export function listNotifications(payload) {
    return {
        type: LIST_ALL_NOTIFICATIONS,
        payload
    }
}

export function setSectionTitle(payload) {
    return {
        type: SET_SECTION_TITLE,
        payload
    }
}

export function setActiveSubject(payload) {
    return {
        type: SET_ACTIVE_SUBJECT,
        payload
    }
}

export function setQuizType(payload, mode) {
    return {
        type: SET_QUIZ_TYPE,
        payload,
        mode: mode
    }
}
export function setStudentsList(payload) {
    return {
        type: SET_STUDENTS_LIST,
        payload
    }
}


    
export function setClassroomsList(payload) {
    return {
        type: SET_CLASSROOMS_LIST,
        payload
    }
}

export function appendInput(payload) {
    return {
        type: APPEND_INPUT,
        payload
    }
}

export function removeInput(payload) {
    return {
        type: REMOVE_INPUT,
        payload
    }
}

export function removeLastInput(payload) {
    return {
        type: REMOVE_LAST_INPUT,
        payload
    }
}

export function editInputValue(payload){
    return {
        type: EDIT_INPUT_VALUE,
        payload
    }
}

export function removeAllInputs(){
    return{
        type:REMOVE_ALL_INPUTS
    }
}

export function setActiveEditor(payload){
    return {
        type: SET_ACTIVE_EDITOR,
        payload
    }
}

export function setActiveNavigationTab(payload) {
    return {
        type: SET_ACTIVE_NAVIGATION_TAB,
        payload
    }
}

export function setCurrentClass(payload) {
    return {
        type: SET_CURRENT_CLASS,
        payload
    }
}

export function setCurrentQuiz(payload) {
    return {
        type: SET_CURRENT_QUIZ,
        payload
    }
}

export function setQuestionTitle(payload){
    return {
        type: SET_QUESTION_TITLE,
        payload
    }
}