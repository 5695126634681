export const pa = {
	auth: {
		login: "تسجيل الدخول",
		logout: "تسجيل الخروج",
		email: "بريد الكتروني",
		rememberMe: "تذكرني",
		signIn: "تسجيل الدخول",
		password_placeholder: "ادخل رقمك السري",
		email_placeholder: "ادخل بريدك الإلكتروني",
		mail_massage: "هذا البريد الالكتروني غير صالح",
		mail_req_massage: "الرجاء ادخال بريدك الالكتروني",
		password: "الرقم السري",
		forgetPassword: "نسيت كلمة السر",
		Forget_Password: "نسيت كلمة السر",
		sammary: "برجاء ادخال البريد الالكتروني لتتمكن من اعاده تعيين كلمة السر",
		passwordMassage: "الرجاء ادخال كلمة السر",
		backToSignin: "الرجوع الي تسجيل الدخول",
		sendMail: "ارسال بريد الكتروني",
		sorry403: "نأسف ، لكن ليس لديك حق الوصول إلى هذه الصفحة أو المورد.",
		sorry505:
			"واجه الخادم خطأ داخليًا أو خطأ في التكوين وكان كذلك لا يمكن إكمال طلبك",
		resetSent: "تم إرسال كود إعادة التعيين بنجاح",
		plaeaseEnterMail:
			"الرجاء إدخال بريدك الإلكتروني ثم انقر فوق الزر أدناه لطلب واحد آخر.",
		confirmPassword: "تأكيد كلمة المرور الجديدة",
		restPass_placeholder: "اعد كتابه رقم المرور",
		confirm_massage: "الرجاء تاكيد كلمة السر",
		Reset: "إعادة تعيين",
		cancle: "الغاء",
		CheckInpoxp1: "يرجى التحقق من مجلد البريد الوارد أو البريد العشوائي في",
		CheckInpoxp2: "للحصول على رابط لإعادة تعيين كلمة المرور الخاصة بك.",
		GoToMail: "اذهب الي البريد الالكتروني",
		Ok: "حسنا",
		goToHome: "اذهب الي الصفحه الرئيسيه",
		oops: "عفوا !!عنوان URLغير صحيح",
		ResendMail: "اعاده ارسال البريد الالكتروني",
		passwordmin: "يجب أن تكون كلمة المرور أقل من 6 أحرف",
		passwordmax: "يجب أن تكون كلمة المرور 20 حرفًا كحد أقصى",
		passwordexpresion:
			"يجب أن تتكون كلمة المرور من 8 أحرف على الأقل بما في ذلك الأحرف الكبيرة والصغيرة.",
		profile: "الصفحه الشخصية",
		personalInfo: "معلومات شخصية",
		name: "الاسم",
		nameMassage: "الرجاء ادخال الاسم",
		namePlaceholder: "ادخل اسمك",
		Security: "الحمايه",
		Account: "حساب",
		Change: "تغيير",
		Deactivate: "تعطيل",
		DeactivateAccount: "تعطيل الحساب",
		SaveChanges: "حفظ التغييرات",
		ChangePassword: "تغيير الرقم السري",
		NoCancel: "الغاء",
		YesDeactivate: "حسنا , قم بتعطيل الحساب",
		PasswordsChanged: "تم تغيير الرقم السري",
		successfullyDeleted: " تم الحذف بنجاح",
		AccountDeactivated: "تم تعطيل الحساب",
		YourAccountDeactivated:
			"تم إلغاء تنشيط حسابك ، يرجى العلم بأنه لن تتمكن أبدًا من تسجيل الدخول مرة أخرى أو استعادة حسابك",
		Delete: "مسح",
		JoinYourClassNow: "سجل الدخول لحسابك الآن!",
		Studentcode: "كود الطالب",
		Studentcoderequired: "يرجى إدخال كود الطالب",
		AreYouSoure: "هل انت متاكد انك تريد مسح هذه المعلومات",
		YesSave: "نعم, احفظ",
		NoSave: "لا تحفظ",
		Cancel: "الغاء",
		EnterPassFor: "الرجاء ادخال الرقم السري الخاص ب",
		TimedOut:
			"لقد انتهت مهلتك بسبب الخمول. يرجى اختيار البقاء تم تسجيل الدخول أو تسجيل الخروج. خلاف ذلك ، سوف يتم تسجيلك أوتوماتيكيا بعد 1 دقيقة.",
		SinginOrLogout: "تم تسجيل الدخول أو تسجيل الخروج",
		otherwise: "خلاف ذلك ، سوف يتم تسجيلك أوتوماتيكيا ",
		minute: "بعد 1 دقيقة.",
		stay: "ابقي",
		inactivityMessage: "لقد تم تسجيل الخروج بسبب عدم النشاط",
		Imagemustsmallerthan5MB: "يجب أن تكون الصورة أصغر من 5 ميغابايت!",
		YoucanonlyuploadJPGPNG: "يمكنك فقط تحميل ملف JPG / PNG!",
		createAccount: "حساب جديد",
		aleadyHaveAccount: "لديك حساب بالفعل ؟",
		signUp: "إنشاء حساب",
	},
	notifi: {
		MarkAll: "تحديد قراءه الكل",
		NotifiContent: "مرحبًا بك مرة أخرى في لوحة إدارة مختبرات Starwallet",
		fiveMin: "خمسه دقائق",
		Delete: "مسح",
	},
	validationMessages: {
		shortDescriptionLength: "الوصف المختصر يجب أن لا يتجاوز 70 حرف",
		descriptionLength: "الوصف يجب أن لا يتجاوز 500 حرف",
		titleLength: "العنوان يجب أن لا يتجاوز 30 حرف",
		titleLengthMax: "العنوان يجب أن لا يتجاوز 60 حرف",
		titleLengthMin: "العنوان يجب أن يكون 3 أحرف على الأقل",
		firstNameLength: "الأسم الأول يجب أن لا يتجاوز 20 حرف",
		firstNameSpaces: "الأسم الأول يجب أن لا يحتوى على أكثر من مسافة واحدة",
		AreYouSureDelete: "هل أنت متأكد من حذف ",
		deleteFile: "حذف ملف",
		fileSizeExceed: "حجم الملف لا يمكن أن يتجاوز ",
		fileNotSupported: " نوع الملفات غير مدعوم",
		imageSize: "حجم الصورة يجب أن يكون أقل من 10 ميجابايت",
		videoSize: "حجم الفيديو يجب أن يكون أقل من 200 ميجابايت",
		invalidFormat: "يرجى إختيار صيغة صورة أو فيديو صحيحة",
		invalidWidth: "عرض الصورة يجب أن يكون أكبر من 400 بكسل",
		invalidHeight: "طول الصورة يجب أن يكون أكبر من 400 بكسل",
		limitExceeded: "لا يمكن إختيار أكثر من 20 ملف",
		invalidImages: "صور غير صالحة",
		maxLength: "يجب ان لا يزيد عدد الحروف علي",
		onlyChar: "يجب ان يحتوي علي 3 جروف علي الاقل",
	},
	home: {
		exportExcel: "تحميل البيانات",
	},
	header: {
		Search: "بحث...",
		Editprofile: "تعديل الملف الشخصي",
		SUPERADMIN: "مدير",
		NOTIFICATIONS: "اشعارات",
		Seeall: "اظهار الكل",
	},
	errors: {
		Unauthorized: "غير مصرح",
		sorry401: "ناسف لا يمكن تنفيذ هذه العمليه",
		goToHome: "اذهب الي الصفحه الرئيسيه",
		sorry403: "نأسف ، لكن ليس لديك حق الوصول إلى هذه الصفحة أو المورد.",
		Access: "غير مسموح بالدخول",
		notFound: "لم يتم الوصول للصفحه",
		error_text:
			"الصفحه التي تبحث عنها ربما تكون قد تم حذفهااو تم تغيير اسمها او غير متاحه حاليا",
		serverError: "خطا بالخادم",
	},
	MScreens: {
		LinkExpired: "انتهت صلاحية الرابط",
		plaeaseEnterMail:
			"الرجاء إدخال بريدك الإلكتروني ثم انقر فوق الزر أدناه لطلب واحد آخر.",
		email: "بريد الكتروني",
		mail_massage: "هذا البريد الالكتروني غير صالح",
		mail_req_massage: "الرجاء ادخال بريدك الالكتروني",
		ResendMail: "اعاده ارسال البريد الالكتروني",
		oops: "عفوا !!عنوان URLغير صحيح",
		Ok: "حسنا",
		PasswordReset: "تم إعادة تعيين كلمات المرور بنجاح",
		ResetPassword: "تعيين رقم سري جديد",
		NewPassword: "رقم سري جديد",
		passwordMassage: "الرجاء ادخال كلمة السر",
		passwordmin: "يجب أن تكون كلمة المرور أقل من 8 أحرف",
		passwordmax: "يجب أن تكون كلمة المرور 20 حرفًا كحد أقصى",
		passwordexpresion:
			"يجب أن تتكون كلمة المرور من 8 أحرف على الأقل بما في ذلك الأحرف الكبيرة والصغيرة.",
		password_placeholder: "ادخل رقمك السري",
		confirmPassword: "تأكيد كلمة المرور الجديدة",
		confirm_massage: "الرجاء تاكيد كلمة السر",
		restPass_placeholder: "اعد كتابه رقم المرور",
		cancle: "الغاء",
		Reset: "إعادة تعيين",
		CheckInpoxp1: "يرجى التحقق من مجلد البريد الوارد أو البريد العشوائي في",
		CheckInpoxp2: "للحصول على رابط لإعادة تعيين كلمة المرور الخاصة بك.",
		goToHome: "اذهب الي الصفحه الرئيسيه",
		backToSignin: "الرجوع الي تسجيل الدخول",
	},
	settings: {
		PasswordsChanged: "تم تغيير الرقم السري",
		successfullyDeleted: " تم الحذف بنجاح",
		ChangePassword: "تغيير الرقم السري",
		NewPassword: "رقم سري جديد",
		passwordMassage: "الرجاء ادخال كلمة السر",
		passwordmin: "يجب أن تكون كلمة المرور 8 أحرف على الأقل",
		passwordmax: "يجب أن تكون كلمة المرور 20 حرفًا كحد أقصى",
		passwordexpresion:
			"تعبير كلمة المرور الذي يتطلب حرفًا صغيرًا واحدًا وحرفًا كبيرًا واحدًا ورقمًا واحدًا",
		password_placeholder: "ادخل رقمك السري",
		confirmPassword: "تأكيد كلمة المرور الجديدة",
		confirm_massage: "الرجاء تاكيد كلمة السر",
		restPass_placeholder: "اعد كتابه رقم المرور",
		logout: "تسجيل الخروج",
		AccountDeactivated: "تم تعطيل الحساب",
		YourAccountDeactivated:
			"تم إلغاء حسابك ، يرجى العلم بأنه لن تتمكن أبدًا من تسجيل الدخول مرة أخرى أو استعادة حسابك",
		YesSave: "نعم, احفظ",
		NoSave: "لا تحفظ",
		Cancel: "إلغاء",
		Delete: "مسح",
		SaveChanges: "حفظ التغييرات",
		AreYouSoure: "هل تريد حفظ البيانات ؟",
		signIn: "تسجيل الدخول",
		EnterPassFor: "الرجاء ادخال الرقم السري الخاص ب",
		password: "الرقم السري",
		passwordMassage: "الرجاء ادخال كلمة السر",
		passwordmin: "يجب أن تكون كلمة المرور 8 أحرف على الأقل",
		passwordmax: "يجب أن تكون كلمة المرور 20 حرفًا كحد أقصى",
		passwordexpresion:
			"تعبير كلمة المرور الذي يتطلب حرفًا صغيرًا واحدًا وحرفًا كبيرًا واحدًا ورقمًا واحدًا",
		password_placeholder: "ادخل رقمك السري",
	},

	setting: {
		Settings: "الإعدادات",
		Integratedappsmasterata: "التطبيقات المتكاملة البيانات الرئيسية",
		GoogleRECAPTCHA: "جوجل RECAPTCHA",
		GoogleAnalyticsTrackingCode: "جوجل تتبع تحليلات مدونة",
		FacebookAppID: "معرف تطبيق Facebook",
		FacebookAppSecret: "Facebook App Secret",
		LinkedInAppID: "لينكدين معرف التطبيق",
		EmailedLinksSettings: "إعدادات ارتباطات بالبريد الإلكتروني",
		Emailverification: "تأكيد بواسطة البريد الالكتروني",
		Enable: "مكن",
		Emailverificationlinkexpirytimeminutes:
			"وقت انتهاء صلاحية التحقق من البريد الإلكتروني (بالدقائق)",
		Mins: "الدقائق",
		EmailNotifications: "اشعارات البريد الالكتروني",
		Resetpasslinkverificationlinkexpirytimeminutes:
			"إعادة تعيين رابط انتهاء صلاحية رابط التحقق (بالدقائق)",
		Resendmailinterval: "إعادة إرسال الفاصل الزمني للبريد",
		Tries: "المحاولات",
		Xplacholder: "xxxx-xxxx-xxxx",
	},

	Privileges: {
		AddRole: "إضافة دور",
		Newrole: "دور جديد",
		Personalinfo: "معلومات شخصية",
		Rolename: "اسم الدور",
		ISACTIVE: "نشط",
		Hidetranslations: "إخفاء الترجمات",
		TranslateIntoAr: "ترجم إلى “عربي“",
		TranslateIntoFr: 'ترجمة إلى "اللغة الفرنسية"',
		Enable: "مكن",
		Rolestatus: "حالة الدور",
		Privileges: "امتيازات",
		Privilege: "امتيازات",
		AddPrivileges: "إضافة الامتيازات",
		SelectPrivileges: "اختر الامتيازات",
		PleaseselectPrivilegestoAssign: "يرجى اختيار الامتيازات لتخصيصها",
		AssignedPrivileges: "الامتيازات المخصصة",
		Admins: "المسؤولين",
		AddAdmins: "إضافة المسؤولين",
		SelectAdmins: "حدد المسؤولين",
		PleaseselectAdminstoAssign: "يرجى اختيار المسؤولين لتعيين",
		deletedSuccessfully: "تم حذف الأدوار بنجاح",
		editedSuccessfully: "تم تعديل الدور بنجاح",
		addedSuccessfully: "تم إضافة دور جديد بنجاح",
	},

	currency: {
		Currencyname: "اسم العملة",
		Addcurrency: "اضف عملة",
		Newcurrency: "عملة جديدة",
		currencyinfo: "معلومات العملة",
	},

	users: {
		USERS: "المستخدمين",
		Name: "الاسم",
		Age: "السن",
		Email: "البريد الإلكتروني",
		Address: "العنوان",
		Admins: "المسؤولين",
		UpdateAdmins: "تعديل المسؤولين",
		Adduser: "إضافة مسؤول",
		Personalinfo: "معلومات شخصية",
		Rules: "الأدوار",
		Addrule: "أضف الدور",
		Selectrule: "اختر الدور",
		PleaseselectruletoAssign: "يرجى اختيار دور لتعيين",
		Assignedrules: "الأدوار المخصصة",
		deletedSuccessfully: "تم حذف المستخدمين بنجاح",
		editedSuccessfully: "تم تعديل المستخدم بنجاح",
		addedSuccessfully: "تم إضافة مستخدم جديد بنجاح",
	},
	grid: {
		New: "جديد ",
		goTo: "الذهاب لصفحة",
		gridSearch: "بحث",
		reset: "إعادة تعيين",
		sureToCancel: "هل أنت متأكد من الحذف؟",
		no: "لا",
	},
	buttons: {
		Close: "أغلق",
		SaveNew: "حفظ و جديد",
		requiredEdit: "مطلوب التعديل",
		Save: "حفظ",
		savePublish: "حفظ و نشر",
		saveForApproval: "حفظ للموافقة",
		UpdateNew: "تحديث و جديد",
		Cancel: "إلغاء",
		Update: "تحديث",
		Duplicate: "كرر",
		Delete: "حذف",
		remove: "حذف",
		Archive: "أرشيف",
		Edit: "تعديل",
		confirm: "هل أنت متأكد من أن تقوم بال",
		selectToEdit: "يرجى إختيار صف للتعديل",
		selectToDelete: "يرجى إختيار صف أو أكثر للحذف",
		change: "تغيير",
		add: "إضافة",
		send: "ارسال",
		cancel: "إلغاء",
		details: "التفاصيل",
		filter: "فلتر",
		reset: "إعادة تعيين",
		saveDrafts: "الحفظ كمسودة",
		ok: "حسنا",
		clone: "نسخ",
		goBack: "عد للخلف",
	},

	inputs: {
		inputrequired: "الحقل مطلوب",
		PleaseinputyourFirstname: "يرجى إدخال اسمك الأول!",
		PleaseinputyourMiddlename: "الرجاء إدخال اسمك الأوسط!",
		PleaseinputyourLastName: "يرجى إدخال اسم العائلة الخاص بك!",
		Pleaseinputyourphonenumber: "يرجى إدخال رقم هاتفك!",
		Firstname: "الاسم الاول",
		Middlename: "الاسم الأوسط",
		LastName: "اسم العائلة",
		PhoneNumber: "رقم الهاتف",
		phonemin: "الهاتف لا يمكن أن يكون أقل من 8 أرقام",
		phonemax: "الهاتف لا يمكن أن يتجاوز 11 رقم",
		phone: "هاتف",
		Email: "البريد الإلكتروني",
		TheinputisnotvalidEmail: "الإدخال غير صالح البريد الإلكتروني!",
		PleaseinputyourEmail: "يرجى إدخال البريد الإلكتروني الخاص بك!",
		EmailAlreadyExists: "البريد الالكتروني موجود بالفعل",
		Firstnamemustcharacters: "يجب أن يكون الاسم  3 أحرف على الأقل",
		namecantacceptspace: "الاسم لا يمكن أن يقبل مسافة",
		Namecanacceptspecialcharacters: "لا يمكن أن يقبل الاسم الأحرف الخاصة",
		Namecanacceptnumbers: "الاسم لا يمكن أن يقبل الأرقام",
		passwordDoesnotMatch: "كلمة المرور و التأكيد غير متطابقين",
	},
	masterData: {
		basicInfo: "البيانات الأساسية",
		label: "البيانات الرئيسية",
		add: "إضافة",
		name: "الإسم",
		email: "البريد الإلكتروني",
		value: "القيمة",
		isActive: "هل مفعل ؟",
		active: "مفعل",
		inActive: "غير مفعل",
		translate: "الترجمة",
		hideTranslations: "إخفاء الترجمة",
		nameMaxLength: "الإسم يجب أن لا يتجاوز 60 حرف",
		nameTranslationMaxLength: "ترجمة الإسم يجب أن لا تتجاوز 60 حرف",
		nameMinLength: "الإسم يجب أن لا يقل عن 3 أحرف",
		nameTranslationMinLength: "ترجمة الإسم يجب أن لا تقل عن 3 أحرف",
		namePlaceholder: "ضع الإسم هنا",
		valuePlaceholder: "ضع القيمة هنا مثال: 25",
		nameTranslationPlaceholder: "ضع ترجمة الإسم هنا",
		deletedSuccessfully: "تم حذف البيانات بنجاح",
		editedSuccessfully: "تم تعديل البيانات بنجاح",
		addedSuccessfully: "تم إضافة البيانات بنجاح",
		theFollowingData: "البيانات التالية ",
		inUseData: "مستخدمة بالفعل ولا يمكن حذفها",
		inUseActivated: " مستخدمة بالفعل ولا يمكن عدم تفعيلها",
	},
	countries: {
		basicInfo: "البيانات الأساسية",
		label: "بلد",
		deletedSuccessfully: "تم حذف البلاد بنجاح",
		editedSuccessfully: "تم تعديل البلد بنجاح",
		addedSuccessfully: "تم إضافة بلد جديد بنجاح",
		nullFlag: "يرجى إختيار صورة",
		countryCurrency: "عملة البلد",
		currencyPlaceholder: "إختر عملة البلد",
		countryCodePlaceholder: "إدخل كود البلد, مثال: +20",
		countryCode: "كود البلد",
	},
	governorates: {
		basicInfo: "البيانات الأساسية",
		label: "محافظة",
		deletedSuccessfully: "تم حذف المحافظات بنجاح",
		editedSuccessfully: "تم تعديل المحافظة بنجاح",
		addedSuccessfully: "تم إضافة محافظة جديدة بنجاح",
	},
	sideBar: {
		Home: "الرئيسية",
		Courses: "المواد",
		Assignments: "الواجبات",
		Exams: "الإختبارات",
		Results: "النتائج",
		Schedule: "جدول الحصص",
	},
	schools: {
		SchoolName: "اسم المدرسة",
		Fax: "فاكس",
		AddSchool: "إضافة مدرسة",
		AddressLine1: "العنوان",
		AddressLine2: "إستكمال العنوان",
		PaymentMethod: "وسيلة الدفع",
		Website: "موقع الكتروني",
		SchoolEmail: "موقع المدرسه الالكترونى",
		AllowedStudents: "الطلاب المسموح لهم",
		ContactPersonName: "اسم شخص الاتصال",
		pleaseEngerName: "الرجاء إدخال الاسم",
		Position: "مركز",
		enterPosition: "الرجاء إدخال مركزك",
		email: "البريد الالكترونى",
		enterEmail: "الرجاء إدخال البريد الالكترونى",
		Country: "البلد",
		State: "المحافظة",
		selectCountery: "اختر بلد",
		selectState: "اختر محافظة",
		addContactPersonData: "إضافة جهة إتصال",
		invalidURL: "يرجى التأكد من إدخال رابط صحيح",
		schoolData: "بيانات المدرسة",
		contactPersonData: "بيانات جهات الإتصال",
		contactWorkPhone: "هاتف العمل",
		contactPersonalPhone: "الهاتف الشخصي",
		addContactModalTitle: "إضافة جهة إتصال",
	},
	payment: {
		PaymentTypes: "أنواع الدفع",
		PaymentHistory: "تاريخ الدفع",
		Chooseyourpaymentplan: "اختر خطة الدفع التي ستتبعها",
		SelectPaymenttype: "اختر طريقة الدفع",
		Features: "المميزات:",
		CurrentStudentsNumber: "عدد الطلاب الحاليين:",
		student: "طالب",
		Changesby: "التغييرات بواسطة:",
		EffectiveDate: "تاريخ التنفيذ",
		LastChanges: "أخر التعديلات",
		seemore: "المزيد",
		CurrentTotalCost: "التكلفة الإجمالية الحالية",
		perStu: "ج م لكل طالب",
		NumberofdaysuntileveryPayment: "عدد الأيام حتى كل دفعة.",
		InspecificDayeverymonths: "في يوم محدد كل شهر.",
		days: "أيام",
		Mon: "أشهر",
	},
	Stations: {
		Newstation: "محطة جديدة",
		ViewStation: "عرض المحطة",
		StationsName: "اسم المحطات",
		NofChargers: "عدد اجهزه الشحن",
		ChoutofService: "خارج الخدمة",
		Governorate: "محافظة",
		Location: "موقع",
		AddNewStation: "أضف محطة جديدة",
		StationName: "اسم المحطة",
		StationNameTranslation: "ترجمة إسم المحطة",
		PleaseInputStationName: "برجاء ادخال اسم المحطة ",
		stationNamePlaceHolder: "اكتب اسم المحطة",
		stationNameTranslationPlaceHolder: "اكتب ترجمة إسم المحطة",
		NumberOfChargers: "عدد الشواحن",
		PleaseInputNumberOfChargers: "برجاء ادخال عدد الشواحن",
		NumberOfChargersPlaceholder: "ادخل عدد الشواحن",
		NumberOfOutOfServiceChargers: "عدد الشواحن خارج الخدمة",
		PleaseinputNumberOfoutOfService: "برجاءادخال عدد الشواحن خارج الخدمة",
		outOfServiceChargersPlaceholder: "ادخل عدد الشواحن خارج الخدمة",
		Governorate: "المحافظة",
		Chargers: "شواحن",
		Available: "متاح",
		Address: "العنوان",
		Directions: "الإتجاهات",
		DirectionsToStation: "الإتجاهات إلى المحطة",
		PleaseInputGovernorate: "برجاء ادخال المحافظة",
		stationGovernorate: "محافظة المحطة",
		LocationOnMap: "الموقع علي الخريطة",
		PleaseInputLocationonMap: "برجاء تحديد الموقع علي الخريطة",
		LocationPlaceholder: "حدد الموقع علي الخريطة",
		chargerIdPlaceholder: "إدخال تعريف الشاحن",
		commentPlaceholder: "إدخال تعليق على الشاحن",
		addCharger: "+ إضافة شاحن",
		chargerID: "رقم الشاحن",
		chargerStatus: "حالة الشاحن",
		chargerComment: "تعليق",
		stationAddedSuccessfully: "تم إضافة محطة شحن جديدة بنجاح",
		stationEditSuccessfully: "تم تعديل محطة الشحن بنجاح",
		chargersNo: "عدد الشواحن",
		Tiles: "المحطات",
		all: "الكل",
		StationsNearYou: "محطات شحن بالقرب منك",
		Findtheneareststation: "هنا تجد محطات الشحن القريبة من موقعك الحالي",
		Map: "الخريطة",
		MoreInfo: "معلومات أكثر",
		outOfServiceChargersNo: "الشواحن خارج الخدمة",
		governateName: "إسم المحافظة",
		alreadyExists: "الشاحن موجود بالفعل",
	},
	Customers: {
		CustomersName: "اسم العميل",
		Email: "البريد الإلكتروني",
		Phone: "الهاتف",
		CardStatus: "حالة البطاقة",
		Lastcharge: "اخر شحن",
		ViewProfile: "عرض الصفحة الشخصية",
		ViewCard: "عرض البطاقه",
		MobilePhone: "رقم التليفون",
		Age: "العمر",
		Gender: "الجنس",
		CarType: "نوع السيارة",
		BatteryRange: "سعة البطارية",
		carlicense: "رخصة القيادة",
		PersonalInfo: "معلومات شخصية",
		CarInfo: "معلومات السيارة",
		Comments: "تعليقات",
		writeyourcommenthere: "اكتب تعليقك هنا",
		new: "جديد",
		active: "نشيط",
		inactive: "غير نشط",
		inProgress: "جاري العمل عليه",
		delivered: "تم التوصيل",
		Requested: "طلب جديد",
		needsAction: "يحتاج لإجراء",
		inDelivery: "جاري التوصيل",
		canceled: "ملغى",
		RequestInfo: "معلومات الطلب",
		address: "العنوان",
		deliveryAddress: "عنوان التوصيل",
		male: "ذكر",
		fmale: "انثي",
		customerBan: "حظر العميل",
		commentSent: "تم ارسال التعليق بنجاح",
		statusUpdated: "تم تغيير الحاله بنجاح",
		userBaned: "تم حظر العميل بنجاح",
		userActivated: "تم تفعيل العميل بنجاح",
	},
	PricingSettings: {
		Settings: "الإعدادات",
		MinimumCharge: "الحد الأدنى للشحن",
		KW: "كيلووات",
		CurrentAC: "التيار المتردد (AC)",
		CurrentDC: "التيار المباشر (DC)",
		ChargersPricingUpdated: "تم تحديث أسعار الشحن بنجاح",
	},
	infinty: {
		EVChargingpoints: "نقاط شحن EV",
		foryourbusiness: "لعملك",
		Morethan17Stations: "أكثر من 17 محطة شحن EV فى القاهرة الكبرى.",
		Locateyourneareststation: "حدد أقرب محطة لك",
		EnvironmentFriendlyDrivingPleasure: "صديقة للبيئة ، متعة القيادة",
		zero: "صفر",
		emissionisourvision: " القضاء علي الانبعاثات هي رؤيتنا",
		Getyour: "احصل على",
		ChargingCard: "بطاقة الشحن",
		ChargingCardArticle:
			"لتكون قادرًا على الشحن في أي محطة شحن من Infinity-e ، اطلب بطاقة الشحن الخاصة بك الآن واستمتع برسوم مجانية لفترة محدودة فقط.",
		GetYourCard: "احصل على بطاقتك",
		Stations: "المحطات",
		EVchargingstationstodate: "محطات شحن EV حتى الآن",
		charginglocations: "مواقع الشحن",
		charginglocationsby2021: "مواقع الشحن بحلول عام 2021",
		Neareststation: "أقرب محطة",
		Setlocation: "تحديد المكان",
		typeyourlocation: "اكتب موقعك هنا ...",
		LocatetheNearestStation: "حدد موقع أقرب محطة شحن Infinity-e",
		Findtheneareststation: "ابحث عن أقرب محطة",
		headerFooter: {
			Home: "الصفحة الرئيسية",
			AboutUs: "معلومات عنا",
			Howitworks: "كيف تعمل",
			Stations: "المحطات",
			ChargingStations: "محطات الشحن",
			RequestCard: "طلب بطاقة",
			ContactUs: "اتصل بنا",
			About: "معلومات ",
			Additionallinks: "روابط إضافية ",
			infintyAdress:
				"57 كورنيش المعادي ، محافظة الجيزة ، القاهرة ، محافظة القاهرة 11431",
			SignIn: "تسجيل الدخول ",
			viewProfile: "الصفحة الشخصية",
			Notifications: "إشعارات",
			AllRightsReserved: "كل الحقوق محفوظة",
		},
	},
	contactusRequests: {
		requestStatus: "حالة الطلب",
		status: "حالة الطلب",
		requestDetails: "تفاصيل الطلب",
		new: "جديد",
		closed: "مغلق",
		close: "غلق",
		needsAction: "يحتاج لإجراء",
		reply: "الرد",
		replyHint: "هذا الرد سيذهب مباشرة للبريد الإلكتروني الخاص بالعميل",
		commentHint: "هذا التعليق سيظهر للمديرين فقط",
		replyPlaceholder: "اكتب ردك على الطلب هنا",
		requestUpdatedSuccessfully: "تم تعديل حالة الطلب بنجاح",
	},
	messages: {
		Messages: "الرسائل",
		Message: "مراسلة",
		NewMessage: "رسالة جديدة",
		AllParents: "جميع اولياء الامور",
		Send: "إرسال",
		AllMessages: "جميع الرسائل",
		Unread: "غير مقروء",
		Read: "مقروء",
		Attachments: "مرفقات",
		Messageparent: "مراسله ولي الامر",
		writeyourmessagehere: "اكتب رسالتك هنا…",
		contactwithyour: "أصبح التواصل مع أولياء أمور الطلاب أمرًا سهلاً",
		sendInstant: "إرسال رسائل فورية لأولياء الأمور",
		selectClassrooms: "إختر الفصول",
		selectStudents: "إختر الطلاب",
		selectSubject: "إختر مادة",
	},
	Classes: {
		Students: "الطلاب",
		Toolkit: "أدوات",
		Homework: "واجب منزلي",
		Attendance: "الحضور",
		Select: "اختيار",
		Multiple: "متعدد",
		SelectMultiple: "اختيار متعدد",
		selectSubject: "إختر المادة",
		set: "إضافة ",
		update: "تعديل ",
		delete: "حذف ",
		SetHomework: "إضافة الواجبات المنزلية",
		UpdateHomework: "تعديل الواجبات المنزلية",
		DeleteHomework: "حذف الواجبات المنزلية",
		viewhomework: "مشاهده الواجبات المنزلية",
		Random: "عشوائية",
		empty: "تواصل مع ولي أمر الطالب بسهوله",
		Timer: "مؤقت",
		inviteParents: "دعوة الآباء",
		HelpingOther: "مساعدة الآخرين",
		OnTask: "في مهمة",
		participating: "المشاركة",
		TeamWork: "مجموعة عمل",
		WorkingHard: "اعمل بجد",
		Givefeedbackto: "اعطاء ملاحظات ل",
		Positive: "إيجابي",
		NeedsWork: "يحتاج للدعم",
		Saveattendance: "حفظ",
		Makeallpresent: "اجعل الكل حاضرين",
		MakeallAbsent: "اجعل الكل غائبا",
		Classroom: " الفصل",
		ClassStory: "اخبار الفصل",
		Profile: "الملف الشخصي",
		Messages: "رسائل ",
		selectAll: "حدد الكل",
		to: "الى:",
		MessageTo: "رسالة إلى:",
		search: "بحث",
		Pleaseselectatarget: "يجب اختيار المرسل اليه",
		Student: "طالب",
		Cancel: "إلغاء",
		next: "التالى",
		send: "ارسال",
		writethemessagetitle: "اكتب عنوان الرسالة",
		writeyourmessagehere: "اكتب رسالتك هنا…",
		pleasewritethemessagetitle: "يرجى كتابة عنوان الرسالة",
		pleasewriteyourmessagehere: "رجاء اكتب رسالتك",
		WholeClass: "كل الفصل",
		SetHomework: "تعيين الواجبات المنزلية",
		Cancel: "إلغاء",
		Wet: "مبلل",
		Dry: "جاف",
		BM: "BM",
		deleteHWconfirm: "هل أنت متأكد من حذف الواجب المنزلي الخاص بهؤلاء الطلاب؟",
		successfullyDeleted: "تم حذف الواجب المنزلي بنجاح",
		Note: "ملاحظه",
		badgeAssignedSuccessfully: "تم تعيين الشارة بنجاح",
		Badges: "شارات",
		Food: "طعام",
		Diaper: "حفاضات",
		sleep: "نوم",
		Breakfast: "وجبة افطار",
		activities: "الأنشطة",
		sickReport: "الحالة المرضية",
		None: "لا شيء",
		Some: "بعض",
		All: "الكل",
		More: "أكثر",
		Snack: "وجبة خفيفة",
		From: "من",
		to: "إلى",
		addMore: " أضف المزيد",
		Lunch: "غداء",
		writehere: "أكتب هنا ملاحظة حول هذا الطفل ..",
		selecttime: "اختر الوقت",
		successfullyAddedFood: "تم إضافة تقرير الوجبات بنجاح",
		successfullyAddedDiaper: "تم إضافة تقرير الحفاظات بنجاح",
		successfullyAddedSleep: "تم إضافة تقرير ساعات النوم بنجاح",
		successfullyAddedNote: "تم إضافة ملاحظات الطالب بنجاح",
		successfullyAddedActivity: "تم إضافة نشاط جديد بنجاح",
		successfullyAddedSickness: "تم إضافة حالة مرضية جديدة بنجاح",
		deleteActivity: "حذف النشاط",
		deleteActivityConfirmation: "هل أنت متأكد من حذف هذا النشاط ؟",
		deleteSickness: "حذف تقرير الحالة المرضية",
		deleteSicknessConfirmation: "هل أنت متأكد من حذف تقرير الحالة المرضية ؟",
		noteRequired: "يرجى ملئ خانة الملاحظات",
		SetHomework2: "تعيين الواجبات المنزلية",
		homeworktitle: "اكتب عنوان الواجب المنزلي.",
		homeworkdetails: "اكتب تفاصيل الواجب المنزلي هنا ....",
		pleasehomeworktitle: "يرجى كتابة عنوان الواجب المنزلي",
		pleasehomeworkdetails: "يرجى كتابة تفاصيل الواجب المنزلي",
		Collapse: "عرض أقل",
		expand: "عرض المزيد",
		Pleaseinputyourusername: "يرجى إدخال اسم المستخدم الخاص بك!",
		timeErrorMessage:
			"لقد اخترت توقيت في المستقبل ، تحقق من (م/ص) أو إختر وقت صحيح",
		AreYouSureDelete: "هل أنت متأكد من حذف ",
		deleteFile: "حذف ملف",
		fileSizeExceed: "حجم الملف لا يمكن أن يتجاوز ",
		fileNotSupported: " نوع الملفات غير مدعوم",
		homeWorkLength:
			" الواجب المنزلي يجب أن لا يتجاوز الـ 1000 حرف أو أن يقل عن 50 حرف",
		homeWorkTitleLength: "عنوان الواجب المنزلي يجب ان يكون بين 3 حروف و 60 حرف",
	},
	userProfile: {
		ChangePassword: "تغيير كلمه السر",
		NameMin: "الاسم لا يقل عن حرفين",
		NameMax: "الأسم يجب ألا يزيد عن 12 حرف",
		Lastname20Max: "لا يمكن أن يتجاوز اسم العائلة 20 حرفًا",
		Firstname20Max: "لا يمكن أن يتجاوز الاسم الأول 20 حرفًا",
		emailMin: "البريد الإلكتروني يجب أن يكون 5 حروف أو أكثر",
		emailMax: "البريد الإلكتروني يجب ألا يزيد عن 150 حرف",
		Name: "الاسم",
		firstName: "الإسم الأول",
		lastName: "إسم العائلة",
		pleaseEnterValidmail: "الرجاء ادخال بريد الكتروني صالح",
		pleaseEntermail: "الرجاء ادخال البريد الالكتروني",
		mail: "البريد الالكتروني",
		Pleaseinputyourphonenumber: "رقم الهاتف يجب ان يكون 11 رقم",
		pleaseEnteraddress: "الرجاء ادخال العنوان",
		pleaseSelectGender: "يرجى تحديد الجنس",
		Male: "ذكر",
		Female: "انثي",
		minmaxaddress: "العنوان يجب أن يكون من 3 إلي 300 حرف",
		ChangePassword: "تغيير الرقم السري",
		PersonalInfo: "معلومات شخصية",
		ChangeYourPassword: "تغيير رقمك السري",
		Uploadnewpicture: "رفع صوره جديده",
		address: "العنوان",
		Currentpassword: "الرقم السري الحالي",
		Newpassword: "الرقم السري الجديد",
		Confirmnewpassword: "تاكيد الرقم السري",
		CarInfo: "معلومات السيارة",
		CarsInfo: "معلومات السيارات الكهربائية",
		ArrivalAddress: "عنوان الوصول",
		sameashomeaddress: "نفس عنوان المنزل",
		oneofourcustomers: "إذا كنت أحد عملائنا ، فيرجى تسجيل الدخول أولاً",
		AddmoreCar: "اضافه المزيد من السيارات",
		Selectcarbrand: "اختار ماركة السيارة",
		Selectcartype: "إختار نوع السيارة",
		pleaseEntercarbrand: "الرجاء ادخال ماركه السيارة",
		cartype: "نوع السيارة",
		pleaseEntercartype: "الرجاء ادخال نوع السيارة",
		pleaseEnterbetteryrange: "الرجاء ادخال سعة البطارية",
		Selectabetteryrange: "اختار سعة البطارية",
		selectUserCar: "يرجى إختيار سيارة",
		Attachcarlicense: "ارفق رخصة السيارة",
		copyofthecarlicense: "نسخة من رخصة السيارة",
		oryoucanattachcustomsreleasecertificate:
			"أو يمكنك إرفاق شهادة الإفراج الجمركي",
		Requests: "طلبات",
		carInfoAdded: "تم إضافة معلومات السيارة بنجاح",
		carlicenseNeed: "يرجى إضافة رخصة السيارة",
		NoRequests: "لا يوجد طلبات",
		youwanttocancel: "هل انت متاكد انك تريد الغاء الطلب",
		fillTheForm: "يرجى ملء النموذج أدناه لطلب بطاقة شحن EV:",
		carsUpdated: "تم تحديث بيانات السيارات بنجاح",
		deleteCar: "حذف سيارة",
		carDeleteConfirmation: "هل أنت متأكد من حذف هذه السيارة ؟",
		Createddate: "تاريخ الإنشاء",
		Status: "الحالة",
		DeliveryAddress: "عنوان التسليم",
		Action: "اجراء",
		Welcome: "مرحبا",
		foraneasierexperience:
			"للحصول على تجربة أسهل ، اجعل حسابك أفضل من خلال استكمال البيانات التالية.",
		Continue: "استمر",
		Skipfornow: "تخطي الآن",
		pleaseEntername: "برجاء ادخال الاسم",
		noLicenceSelected: "يرجى إضافة صور الرخصة",
		licenseFront: "يرجى إضافة صورة الرخصة الأمامية",
		licenseBack: "يرجى إضافة صورة الرخصة الخلفية",
		Wallet: "المحفظة",
	},
	contactUs: {
		fullName: "الاسم كامل",
		email: "البريد الالكتروني",
		RequestDetails: "تفاصيل الطلب",
		PleaseinputyourRequestDetails: "الرجاء ادخال تفاصيل الطلب",
		HereToHelp: "نحن هنا للمساعدة",
		ContactUS: "تواصل معنا",
		ContactDetails: "تفاصيل التواصل",
		OpeningHours: "ساعات العمل",
		Closed: "مغلق",
		About: "عن",
		Howitworks: "كيف تعمل",
		ChargingStations: "محطة الشحن",
		RequestCard: "طلب بطاقة",
		Additionallinks: "روابط اضافية",
		AboutUs: "عنا",
		subjectMin: "الموضوع يجب أن يكون 20 حرف على الأقل",
		subjectMax: "الموضوع يجب أن يكون 200 حرف بحد أقصى",
		nameMin: "الأسم يجب أن لا يقل عن حرفين",
		nameMax: "الأسم يجب أن لا يتجاوز 60 حرف",
		dayopen: "الأحد  -الخميس",
		dayclose: "الجمعة-السبت",
	},
	notifications: {
		loadMore: "عرض المزيد من التنبيهات",
		notifications: "التنبيهات",
		noNotifications: "ليس لديك تنبيهات",
		today: "اليوم",
	},
	aboutUs: {
		aboutUsPragraph:
			"تأسست Infinity-e  في عام 2018 وهي شركة تابعة لشركة Infinity إنرجي ، التي تقود قطاع الطاقة المتجددة في مصر. توفر Infinity-e حلول شحن للسيارات الكهربائية ، وتهدف إلى إنشاء شبكة شحن EV موثوقة ومريحة في مصر. تتوفر نقاط شحن EV الخاصة بنا في محطات Chillout على الطرق السريعة والطرق الرئيسية ، بالإضافة إلى مناطق حركة المرور العالية ، مثل مراكز التسوق ومباني المكاتب ، وما إلى ذلك ، بالإضافة إلى مجتمعات سكنية مسورة مختارة ، مثل مشاريع تطوير بالم هيلز ، إلخ.",
		Exploremore: "استكشاف المزيد",
		Ourstations: "محطاتنا",
		OurstationsPragraph1:
			"محطاتنا هي دليل مستقبلي مع جميع شواحن التيار المتردد التي نقدمها بقوة 22 كيلو واط في كل مقبس ، تتراوح شواحن التيار المستمر من 50 كيلو واط مع توفيرات تصل إلى 175 كيلو واط في المستقبل القريب للتأكد من أن لديك وقت للشحن السريع أثناء السير على الطريق. توفر أجهزة الشحن السريع 80٪ من سعة الشحن في أقل من ساعة واحدة.",
		OurstationsPragraph2:
			"جميع المحطات هي معايير الاتحاد الأوروبي التي تلبي احتياجات النوع 2 لشواحن التيار المتردد (يمكن شحن السيارات من النوع الأول باستخدام النوع 2 لكابلات النوع الأول) ، CCS2 و CHAdeMO. نحن نشحن ما يصل إلى 4 سيارات في محطة واحدة وبعض المحطات تلبي 6 EVs في المرة الواحدة.",
		Our: "",
		Partners: "شركائنا",
	},
	howItWork: {
		How: "كيف",
		itworks: "تعمل",
		Start: "اولا",
		StartStep: "قم بتوصيل كابل الشاحن بالسيارة والمحطة.",
		Second: "ثانيا",
		SecondStep: "ابدأ محاضرة الشحن عن طريق تمرير بطاقة الشحن.",
		Third: "ثالثا",
		ThirdStep: "يشير ضوء LED الأزرق إلى بدء محاضرة الشحن.",
		End: "اخيرا",
		EndStep: "أوقف محاضرة الشحن عن طريق تمرير بطاقة الشحن ثم إزالة الكابل.",
		RequestCard: "طلب بطاقة",
		Requestcharging: "طلب بطاقة شحن لك",
		Search: "بحث",
		Searchlocation: "ابحث عن موقع المحطات هنا ببساطة",
	},
	signIn: {
		WelcomeBack: "مرحبا بعودتك !",
		Tokeepconnected:
			"للبقاء على اتصال معنا يرجى تسجيل الدخول باستخدام معلوماتك الشخصية",
		SignIn: "تسجيل الدخول",
		SignUp: "إنشئ حساب جديد",
		HelloFriend: "مرحبا يا صديقي!",
		personaldetails: "أدخل تفاصيلك الشخصية وابدأ رحلتك معنا",
		SignintoInfinity: "سجّل الدخول إلى Infinity",
		Forgotpassword: " نسيت رقمك السري ؟",
		haveaccount: "ليس لديك حساب؟",
	},
	courses: {
		TodaysCourses: "دروس اليوم",
		more: "المزيد",
		Day: "يوم",
		Week: "أسبوع",
		Month: "شهر",
		All: "الكل",
		ToDo: "لم ينتهي",
		Completed: "منتهي",
		Draft: "مسودة",
		NoCoursesAdded: "لم تتم إضافة دروس",
		allCourses: "كل الدروس",
		noClassroomSelected: "لا يوجد فصول مختارة",
		coursesSubtitle: "دروس لتكملها اليوم",
		attachments: "المرفقات",
		downloadAll: "تحميل الكل",
		maxLimit: "تم الوصول للحد الاقصي من الملفات ",
		successfullyEditedCourse: "تم تعديل الكورس بنجاح",
		attachmentRequired: "يرجى إرفاق ملف واحد على الأقل",
		successfullyAddedCourse: "تم إضافة كورس جديد بنجاح",
		All: "الكل",
		AuthorName: "اسم المعلم",
		AssignedbyTeacher: "المعين من قبل المعلم",
		Target: "الفئة المستهدفة",
		Attachment: "المرفق",
		AllClasses: "جميع الفئات",
		CourseDetails: "تفاصيل الدرس",
		Publishingdetails: "تفاصيل النشر",
		CourseSubject: "مادة الدرس",
		selectSubject: "حدد المادة",
		CourseTitle: "عنوان الدرس",
		selectTitle: "حدد العنوان",
		CourseDescriptions: "وصف الدرس",
		ReleaseDate: "تاريخ النشر",
		StudentsTarget: "الطلاب المستهدفة",
		NoselectedTarget: "لم يتم تحديد فئة",
		AddAttatchments: "أضف مرفقات",
		limitExceeded: "تم تجاوز عدد الملفات المسموحة",
		newCourse: "إضافة درس",
		editCourse: "تعديل الدرس",
		// editCourse: 'تعديل درس',
		chapter: "الفصل",
		topics: "المواضيع",
		courses: "الكورسات",
		selectClass: "تحديد الفصل",
		selectSubject: "تحديد المادة",
		courseTitleRequired: "يرجى ادخال اسم الدرس",
		releaseDateRequired: "يرجى ادخال تاريخ النشر",
		descriptionRequired: "يرجي ادخال الوصف",
	},
	filtrationBar: {
		filter: "فلترة",
		subject: "المادة",
		date: "التاريخ",
		courseName: "إسم الدرس",
		subjectPlaceholder: "اختر المادة",
		datePlaceholder: "اختر التاريخ",
		courseNamePlaceholder: "اكتب إسم المادة هنا...",
		allYear: "العام كله",
		clear: "ازالة",
		ResultType: "نوع النتيجة",
		ResultEvaluation: "تقييم النتيجة",
	},
	assigments: {
		Assigments: "الواجبات",
		Assignment: "الواجب",
		NoAssigmentsAdded: "لم تتم إضافة مهام",
		NoFeedbackyet:
			"لا توجد تعليقات بعد ، أكمل مهمتك أولاً وانتظر ملاحظات معلمك",
		SuccessfullyPass: "اجتاز بنجاح",
		Youhave: "لديك ",
		assigmentstocompletetoday: "المهام لإكمال اليوم ",
		All: "الكل",
		ToDo: "المهام",
		Late: "متأخر",
		Feedback: "تقييم",
		Completed: "اكتمل",
		Uncomplet: "غير مكتملة",
		open: "افتح",
		Feedback: "تقييم",
		ReSubmit: "إعادة إرسال",
		Teacherfeedback: "ملاحظات المدرس",
		TodayAssigments: "مهام اليوم",
		Applyyourassignmenthere: "ارسل واجبك هنا..",
		AssignmentReply: "تعيين رد",
		doesntmatchtherequirement: "لا يطابق المطلوب.",
	},
	exams: {
		exams: "الإختبارات",
		headerSubtitle: "اختبارات لإكمالها اليوم",
		all: "الكل",
		toDo: "الإختبارات",
		upComing: "الإختبارات القادمة",
		previous: "الإختبارات السابقة",
		online: "أونلاين",
		printed: "مطبوع",
		hours: "ساعات",
		hour: "ساعة",
		questions: "سؤال",
		startExam: "إبدء الإختبار",
		instructions: "التوجيهات",
		daysLeft: "أيام متبقية",
		Beforeyoubegin: "قبل ان تبدأ",
		Themaximumtime:
			"الحد الأقصى للوقت الذي يسمح له بالوصول إلى الاختبار بعد وقت البدء.",
		Theresnooption: "ليس هناك خيار للتوقف. تأكد من أنك لن تنقطع ل",
		Ifyouaccidentallyclose:
			"إذا أغلقت المتصفح عن طريق الخطأ ، فاستخدم رابط الدعوة للعودة إلى الاختبار.",
		Hintyoucanuse:
			"تلميح: يمكنك استخدام IDE الخاص بك واستخدام لصق النسخ ، ولكن تأكد من تجميع الحل الخاص بك في بيئة اختبار Scholigit.",
		HowtouseitInstruction: "كيفية استخدامه: تعليمات ونصائح",
		minutes: "دقيقة",
		Hours: "ساعات",
		StartTime: "وقت البدء",
		EndTime: "وقت النهاية",
		ExamDuration: "مدة الامتحان",
		NQuestions: "عدد الأسئلة",
		Questions: "الأسئلة",
		Accessibilitytime: "وقت الوصول",
		FromstartingTimeTill: "من البداية حتى الوقت",
		checkTipNumberbelow: "تحقق من نصيحة رقم 1 أدناه",
		noExamsFound: "لا يوجد إمتحانات في الوقت الحالي",
		underCorrection: "جاري التصحيح",
	},
	Results: {
		Results: "النتائج",
		withinthetotal: "ضمن المجموع",
		Outoftotal: "خارج المجموع",
		Excellent: "ممتاز",
		VGood: "جيد جدا",
		Good: "جيد",
		Congratulations: "تهانينا",
		successfullypassed: "، لقد نجحت يتم الانتقال إلى الصف التالي.",
		LatestResult: "آخر النتائج",
		Archive: "أرشيف",
		NoResultsfoundyet: "لم يتم العثور على نتائج بعد",
		Yourresultswillappearhere: "ستظهر نتائجك هنا بمجرد انتهاء التصحيح",
		MoreDetails: "المزيد من التفاصيل",
	},
	timeline: {
		addPhoto: "أضف صورة",
		addVideo: "أضف فيديو",
		Photo: "صورة",
		Video: "فيديو",
		File: "ملف",
		post: "إرسال",
		TimeLine: "الحائط المدرسي",
		Calender: "التقويم",
		classes: "الفصول",
		messages: "الرسائل",
		subjects: "المواد",
		Attendens: "الحضور",
		TimeTable: "جدول الحصص",
		HomeWork: "الواجبات المنزلية",
		Exam: "الإمتحانات",
		onlineSessions: "المحاضرات المباشرة",
		Cancel: "إلغاء",
		createTagline: "ماذا يحدث في فصلك؟",
		captionRequired: "نص المنشور مطلوب",
		postCreatedSuccessfully: "تم إضافة المنشور بنجاح",
		cropImage: "تعديل الصورة المختارة",
		crop: "تعديل",
		imageSize: "حجم الصورة يجب أن يكون أقل من 10 ميجابايت",
		videoSize: "حجم الفيديو يجب أن يكون أقل من 200 ميجابايت",
		invalidFormat: "يرجى إختيار صيغة صورة أو فيديو صحيحة",
		invalidWidth: "عرض الصورة يجب أن يكون أكبر من 400 بكسل",
		invalidHeight: "طول الصورة يجب أن يكون أكبر من 400 بكسل",
		limitExceeded: "لا يمكن إختيار أكثر من 20 ملف",
		invalidImages: "ملفات غير صالحة",
		DropToUpload: "قم بسحب الملفات هنا للتحميل",
		Upload: "تحميل",
	},

	attendance: {
		status: "الحالة",
		studentName: "اسم الطالب",
		notes: "ملاحظات",
		class: "الفصل",
		attendees: "الحضور",
		present: "حاضر",
		absent: "متغيب",
		note: "ملاحظة",
		causeOfAbsence: "سبب الغياب",
		downloadPDF: "PDF تحميل ",
		presentALL: "حضور الكل",
		absentAll: "غياب الكل",
		date: "التاريخ  ",
		Save: "حفظ",
		Cancel: "الغاء",
		AddNote: "اضافة ملاحظة",
		recording: "التسجيلات",
		files: "الملفات",
		chat: "الدردشة",
		onlineSession: "المحاضرات المباشرة",
	},
	classroomStudents: {
		ShowAttend: "أظهر الحاضرين",
		noDataMessage: "لا يوجد تلاميذ لهذا الفصل",
		studentModalTitle: "إعطاء ملاحظات ل  ",
		WholeClass: "كل الفصل",
		badges: "الوسوم",
		attended: "حاضر",
		absent: "غائب",
	},
	homeworkList: {
		date: "تاريخ البدء",
		edit: "تعديل",
		titleClass: " العنوان",
		subject: "المادة",
		dueDate: "تاريخ الانتهاء",
		solvedNo: "المجابه",
		degree: "الدرجة ",
		details: "التفاصيل",
		correction: "تم تصحيحه",
		noDataMessage: "لا يوجد واجبات لهذا اليوم ، من فضلك قم بأختيار يوم اخر",
		clone: "نسخ الواجب",
		removeAll: "مسح الكل",
		add: " اضافة فصل",
		search: "بحث",
		startDate: "تاريخ البدء",
		endDate: "تاريخ الانتهاء",
		startEndDateMessage: "الرجاء تحديد تاريخ البدء والانتهاء",
		classroomHasHomeworkMsg: "هذا الفصل لديه بالفعل واجب منزلي",
		delete: "مسح",
		colnedSuccess: "تم نسخ الواجب المنزلي بنجاح",
		target: "المستهدف",
		students: "الطلاب",
		all: "كل",
		classRoom: "الفصل",
		unsavedDatatitle: "البيانات غير المحفوظة",
		unsavedDataMessage: "هل أنت متأكد أنك تريد تجاهل البيانات غير المحفوظة؟",
		ignore: "تجاهل",
		cancel: "الغاء",
		fetchSuccess: "تم نقل الواجب بنجاح",
		cloneOnly: "نسخ",
		correctionStatus: "حالة التصحيح",
		ready: "جاهز",
		readyToolTip: "جاهز للتصحيح",
		done: "تم",
		unCompleted: "غير مكتمل",
		notViewed: "لم يشاهد",
	},
	homeworkBar: {
		homeWork: "الواجبات",
		addHomeWork: "اضافة واجب",
		fetch: "نقل واجب",
		title: "العنوان",
		needCorroction: "تحتاج إلى تصحيح",
	},
	homeworkStudentList: {
		Name: "اسم الطالب",
		Status: "حالة الأسئلة",
		NoQuestons: "عدد الاسئلة",
		NotCorrocted: "غير مصحح",
		Corrected: " مصحح",
		Degree: "درجة",
		Details: "تفاصيل",
		All: "الكل",
		UnCompleted: "غير مكتمل",
		Done: "منتهي",
		NotViewed: "لم تتم مشاهدته",
		SolvingDuration: "زمن الحل ",
		readyForCorrection: "جاهز للتصحيح",
		Min: "دقيقة",
		InProgress: "In Progress",
		ready: "جاهز",
		notReady: "غير جاهز",
	},
	classNavigation: {
		classRoom: "الفصل",
		classStory: "أخبار الفصل",
		messages: "الرسائل",
		specialNote: "الملحوظات",
		timeTable: "جدول الحصص",
		homework: "الواجبات",
		attendance: "الحضور",
		exams: "الإمتحانات",
	},
	studentHomework: {
		mark: "درجة",
		addHomeWork: "اضافة واجب",
		correct: "صحيح",
		corrected: "تصحيح",
		incorrect: "غير صحيح",
		partialResult: "نتيجة جزئية",
		finish: "انهاء",
		finishNext: "انهاء و عرض الطالب التالي",
		homeWork: "واجب",
	},
	quizQuestionsTypes: {
		True: "صح",
		False: "خطأ",
		paragraphPlaceholder: "قم بكتابة اجابتك هنا",
		AddAnotherChoice: "أضف خيارًا آخر",
		Youmustfillallinputes: "يجب عليك ملء جميع المدخلات",
		Youcantaddnewone: "لا يمكنك إضافة خيار آخر",
		Youmustmarkatleastonchoiceascorrectanswer:
			"يجب تحديد خيار واحد على الأقل كإجابة صحيحة",
	},
	studentQuiz: {
		addAnotherQuestion: "إضافة سؤال اخر",
		quiz: "اختبار",
		selectContent: "اختيار النوع",
		uploadfile: "تحميل ملف",
		duplicate: "مضاعفة",
		remove: "حذف",
		quizTitle: " عنوان الاختبار ",
		questionTitle: "رأس السؤال",
		multipleChoice: "متعدد الاختيارات",
		singleChoice: "اختيار وحيد",
		trueFulse: "صح و خطأ",
		truee: "صح & ",
		Falsee: " خطأ ",
		paragraph: "فقرة",
		smallParagraph: "فقرة صغيرة",
		completeThefollowing: "اكمل ما يلي",
		reArrange: "اعادة ترتيب",
		Tagquestions: "وصل",
		QuestionTitleRequired: "رأس السؤال مطلوب",
		QuizModeRequired: "يجب اختيار وضع الاختبار",
		homeworkTitle: "عنوان الواجب",
		homeworkRequired: "عنوان الواجب مطلوب",
		StartDate: "تاريخ البداية",
		removeCurrentQuiz: "سوف يتم خسارة الأسئلة الحالية",
		EndDate: " تاريخ النهاية ",
		TotalDegree: "مجموع الدرجات",
		SessionSubject: "موضوع المحاضرة",
		QuizTitle: "عنوان الاختبار",
		QuizTitleRequired: "يجب اختيار عنوان الاختبار",
		option: "اختيار",
		startTimeRequired: "بجب اختيار تاريخ البداية",
		dueDateRequired: "يجب اختيار تاريخ النهاية ",
		startDateRequired: "بجب اختيار تاريخ البداية",
		dueTimeRequired: "يجب اختيار تاريخ النهاية ",
		totalDegreeRequired: "يجب تحديد الدرجة الكلية",
		titleRequired: "يجب اختيار موضوع المحاضرة",
		whiteSpace: "يجب الا يحتوي علي مسافة فقط",
		sortQuestions: "ترتيب الأسئلة",
		editQuestion: "تعديل السؤال",
		removeQuiz: "هل تريد الغاء جميع الاسئلة",
		removeSelectedQuiz: "هل تريد مسح هذا السؤال",
		deleteQuiz: "مسح الأسئلة",
		SelectQuiz: "من فضلك اختر نوع السؤال",
		SelectQuestion: "من فضلك اختر نوع السؤال",
		DragQuestions: "اسحب الأسئلة للترتيب",
		addQuestions: "إضافة سؤال",
		corroctAnswer: "الاجابة الصحيحة",
		questionsHeaderRequired: "يجب ادخال رأس السؤال",
		Question: "السؤال",
		choiceValidation: "يجب اختيار علي الاقل اجابة واحدة صحيحة",
		connectQuestionsValidation: "يجب ايصال جميع الاسئلة بالاجابات",
		TrainingMode: "وضع التدريب",
		ExamMode: "وضع الاختبار",
		MultipleMode: "وضع متعدد",
		generalMode: "وضع عام",
		descriptionRequired: "يجب ادخال الوصف",
		homeworks: "الواجبات",
		description: "الوصف",
		deleteHomework: "Delete Homework",
		deleteHomeworkConfirm: "هل انت متأكد انك تريد مسح هذا الواجب ؟!",
		quizInDate: "تحذير",
		removeQuizInDate:
			"يوجد واجب اخر في هذا اليوم هل تريد مسح الاسئلة الحالية ؟",
		true: "صح",
		false: "خطأ",
		enterOption: "اكتب الاختيارات",
		uploadFile: "تحميل ملف",
		recordAudio: "تسجيل صوتي",
		warning: "تحذير",
		cancelEdit: "هل انت متأكد من انك تريد مسح التعديلات ",
		cancelAdding: "هل انت متأكد من انك لا تريد اضافة هذا السؤال ؟",
		yes: "نعم",
		no: "لا, اسنمر في التعديل",
		accessMice: "يجب ان تسمح لاستخدام الميكرفون الخاص بك",
		deleteQuestion: "مسح السؤال",
		deleteHomeworkSolvedConfirm: "يوجد طالب او اكثر قاموا بحل هذا الواجب ؟",
		complete: "اكمل",
		addOptions: "يجب ان تضيف اختيارات",
		fillOptions: "يجب كتابة هذا الاختيار",
		cancel: "الغاء",
		option: "الاختيار",
		addSections: "اضافة أقسام",
		disabledAdd: "لا يمكن اضافة اسئلة لامتحان تم تعيينه",
	},
	connectQuestionType: {
		AddItem: "اضافة عنصر",
		Clear: "مسح",
		Edit: "تعديل",
		Delete: "حذف",
		Done: "تم",
	},
	onlineSessionList: {
		ClassOnlineSession: "محاضرة مباشرة",
		Show: "أظهار",
		All: "الكل",
		Started: "بدأت",
		Ended: "انتهت",
		Scheduled: "مقررة",
		Recorded: "مسجلة",
		details: "التفاصيل",
		CreateOnlineSession: "انشاء محاضرة مباشرة",
		Title: "العنوان",
		Subject: "المادة",
		Target: "الحاضرين",
		DateTime: "الوقت/التاريخ",
		Status: "الحالة",
		Actions: "أجراءات",
		Join: "ابدأ / أنضم",
		Edit: "عدل",
		Summary: "الملخص",
		Recordable: "مسجله",
		Delete: "حذف",
		EmptyMessage: "عذرا لا يوجد محاضرات من فضلك جرب اختيار اخر",
		NotApplicable: "لا ينطبق",
		ConfirmationTitle: "تأكيد",
		ConfirmationContent: "هل تريد حذف هذه المحاضرة؟",
		Attention: "انتباة",
		SessionEnded: "انتهت المحاضرة",
		SessionNotStarted: "لم تبدأ المحاضرةبعد",
		RemainingTimeToStart: "الوقت المتبقي للبدء ",
		EndTime: "وقت النهاية",
		Hours: "ساعة",
		Days: "يوم",
		Minutes: "دقيقة",
		StartDate: "وقت البدء",
		EndDate: "وقت الانتهاء",
		SelectType: "الحالة ",
		Students: "طلاب",
		SearchStudents: "ابحث بأسم الطالب",
	},
	onlineSession: {
		createOnlineSession: "إنشاء محاضرة أونلاين",
		titleRequired: "يجب ادخال عنوان المحاضرة",
		subjectRequired: "يجب إختيار المادة",
		chapterRequired: "يجب إختيار الوحدة",
		topicRequired: "يجب إختيار درس",
		classRoomRequired: "يجب ادخال اسم الفصل",
		attachmentsRequired: "المرفقات مطلوبة",
		title: "عنوان المحاضرة",
		selectSubject: "اختيار المادة",
		recording: "التسجيل مفعل",
		date: "التاريخ",
		notRecording: "التسجيل موقوف",
		endTimeRequired: "يجب اختيار وقت النهاية",
		downlodable: "قابل للتحميل",
		SendTo: "ارسال الى..",
		class: "فصل",
		student: "الطالب",
		removeall: "حذف الكل",
		startEndDate: "وقت الانتهاء يجب ان يكون اكبر من وقت البداية",
		selectClassRoom: "اختر الفصل",
		selectStudent: "اختر التلاميذ",
		general: "عام",
		course: "دورة دراسية",
		noSelectedStudent: "لا يوجد تلاميذ مختارة",
		selectGrade: "اختر سنة دراسية",
		selectChapter: "إختر الوحدة",
		selectTopics: "إختر الدروس",
		createdSuccessfully: "تم انشاء المحاضرةبنجاح",
		updatedSuccessfully: "تم تحديث المحاضرةبنجاح",
		spacesStart: "لا يمكن بداية العنوان بمسافة",
		minSession: "المحاضرةيجب الا نقل عن 15 دقيقة",
		maxSession: "المحاضرةيجب الا تزيد عن 3 ساعات",
		currentTimeValidation: "وقت بداية المحاضرةيجب ان يكون بعد ساعة من الان",
		haveToStudents: "يجب اختيار طلاب",
		StudentRequired: "يجب اختيار طلاب",
		maxStudent: "يجب اختيار 40 طالب او اقل",
		note: "ملاحظة : ",
		youInvited: "لقد قمت بدعوة",
		from: "من",
		dateRequired: "بجب اختيار التاريخ",
	},
	filtration: {
		searchBy: "البحث ب",
	},
	calendar: {
		day: "يوم",
		week: "أسبوع",
		month: "شهر",
		calendar: "التقويم",
		youHave: "لديك",
		remainingTasks: "مهام متبقية",
		timeLine: "الجدول الزمني ",
		CreateEvent: "انشاء حدث",
		CreateOnlineSession: "إنشاء محاضرة مباشرة",
		Students: "طالب",
		Agenda: "الأجندة",
	},
	calenderHeader: {
		Show: "عرض",
		TimeTable: "الجدول الزمني",
		Event: "حدث",
		ClassOnline: "المحاضرات المباشرة",
	},
	addChaptersTopics: {
		AddChapter: "اضافة فصل",
		AddTopic: "اضافة موضوع",
		ChapterName: "اسم الفصل",
		ChapterNameRequired: " اسم الفصل مطلوب",
		TopicName: "  اسم الموضوع",
		TopicNameRequired: " مطلوب اسم الموضوع",
		Cancel: "الغاء",
		Save: "حفظ",
		Ok: "حسنا",
		Edit: "تعديل",
		Delete: "حذف",
		ConfirmationMsg: "هل أنت متأكد أنك تريد حذف هذا البند؟",
		Attention: "انتباه",
		Semester: "التيرم",
		SemesterRequired: " التيرم مطلوب ",
		Done: "تم",
		AddNewTopic: "أضف موضوع جديد",
		AddNewChapter: "أضف فصل جديد",
	},
	homeWorkToolbar: {
		Type: "النوع",
		TotalDegree: "الدرجة الكلية",
		StartDate: "تاريخ البدء",
		DueDate: "تاريخ الاستحقاق",
		Target: "target",
		HomeWork: "واجب منزلي",
		Quiz: "اختبار",
		CustomDate: "تاريخ مخصص",
		AllClass: "كل الفصل",
		CustomStudents: "تخصيص طلاب",
		StudentSelected: "طالب محدد",
		Save: "حفظ",
		Cancel: "الغاء",
		QuizTitle: "عنوان الإختبار",
		QuizMode: "وضع الاختبار",
		ExamMode: "وضع الامتحان",
		questionTitle: "رأس السؤال",
	},
	exam: {
		Exam: "امتحان",
		Class: "فصل",
		startExam: "بدء الامتحان",
		continueExam: "إستكمال الامتحان",
		finishExam: "الانتهاء من الامتحان",
		next: "التالى",
		previous: "السابق",
		FinishExam: "إنهاء",
		finishAndNext: "إنهاء و الذهاب للطالب القادم",
		solved: "تم حلها",
		NotSolved: "غير محلول",
		MarkForReview: "علامة للمراجعة",
		minutes: "دقيقة",
		Hours: "ساعات",
		StartTime: "وقت البدء",
		EndTime: "وقت النهاية",
		TotalResult: "النتيجة الإجمالية",
		AutoCorrecred: " التصحيح التلقائي",
		Written: "مكتوب",
		All: "الكل",
		ManualCorrection: "تصحيح يدوي",
		Filterby: "فرز بواسطة",
		SwitchStudent: "تبديل الطالب",
		ExamDuration: "مدة الامتحان",
		NQuestions: "عدد الأسئلة",
		Questions: "الأسئلة",
		Accessibilitytime: "وقت الوصول",
		FromstartingTimeTill: "من البداية حتى الوقت",
		checkTipNumberbelow: "تحقق من نصيحة رقم 1 أدناه",
		Beforeyoubegin: "قبل ان تبدأ",
		Themaximumtime:
			"الحد الأقصى للوقت الذي يسمح له بالوصول إلى الاختبار بعد وقت البدء.",
		Theresnooption: "ليس هناك خيار للتوقف. تأكد من أنك لن تنقطع ل",
		Ifyouaccidentallyclose:
			"إذا أغلقت المتصفح عن طريق الخطأ ، فاستخدم رابط الدعوة للعودة إلى الاختبار.",
		Hintyoucanuse:
			"تلميح: يمكنك استخدام IDE الخاص بك واستخدام لصق النسخ ، ولكن تأكد من تجميع الحل الخاص بك في بيئة اختبار Scholigit.",
		HowtouseitInstruction: "كيفية استخدامه: تعليمات ونصائح",
		Areyouready: "هل أنت جاهز ؟",
		Theexamisnotvalidanymore: "الامتحان غير صالح بعد الآن",
		ThisexamURLisnotvalidanymore:
			"لم يعد عنوان URL هذا للاختبار صالحًا ، فهو للاستخدام مرة واحدة فقط",
		GoToExamsPage: "الانتقال إلى الصفحة الامتحانات",
		question: "السؤال",
		point: "النقطه",
		MultiChoise: "إختر من متعدد",
		SingleChoise: "إختر إجابة واحدة",
		Choise: "إختر",
		Written: "الفقرة",
		Trueorfalse: "صحيح أم خطأ",
		TOF: "ص ام خ",
		true: "صح",
		false: "خطأ",
		connect: "وصل",
		rearrange: "أعد الترتيب",
		complete: "أكمل",
		examSubmitted: "تم إرسال الإمتحان بنجاح",
		wellDone: "أحسنت",
		youDidGreat: "لقد قمت بعمل عظيم",
		yourResult: "نتيجك",
		done: "إنهاء",
		waitForCorrection:
			"يرجى الإنتظار لحين الإنتهاء من تصحيح الإمتحان الخاص بك, سوف يتم تنبيهك حين الإنتهاء من التصحيح",
		keepInMind:
			'ضع في اعتبارك أنه عند النقر فوق "إنهاء" ، هذا يعني أنك قد أنهيت الاختبار ولا يمكنك الرجوع إلى إجاباتك مرة أخرى.',
		TextAreaTypeAnswer: "اكتب اجابتك هنا",
		marks: "درجات",
		outOf: "من",
		Correct: "صحيح",
		Incorrect: "غير صحيح",
		PartialResult: "نتيجة جزئية",
		switchStudentParagraph: "هل أنت متأكد من التبديل لطالب آخر ؟",
		switch: "تبديل",
		All: "الكل",
		studentTotalDegree: " الدرجة الكلية لهذا الطالب هي",
		confirmResult: "قم بتأكيد هذه الدرجة كدرجة نهائية",
		qLetter: "س",
	},
	examList: {
		Subject: "المادة",
		Degree: "الدرجة",
		Duration: "المدة",
		Questions: "سؤال",
		ExamType: "نوع الامتحان",
		Status: "الحالة",
		WaitForApprove: "ينتظر الموافقة",
		InProgress: "جاري المعالجة",
		NotViewed: "لم يشاهد",
		Rejected: "يحتاج لتعديل",
		AssignedTo: "مخصص لـ",
		minutes: "ساعات",
		Montley: "شهري",
		Midterm: "نصف التيرم",
		Final: "نهائي",
		training: "تدريب",
		Correctors: "المصححين",
		SwitchToTraining: "نقل إلى وضع التدريب",
		Delete: "حذف",
		Edit: "تعديل",
		CorrectorsAdded: "تمت إضافة مصححين",
		RequiredCorrectors: "مطلوب مصححين",
		NoTeachersForCorrection: "لم يتم وضع أي معلم من أجل التصحيح",
		ThereAre: "يوجد",
		Teacherstocorrecttheexam: "معلم لتصحيح الامتحان",
		Exams: "الامتحانات",
		CreateExam: "إنشاء امتحان",
		myExams: "إمتحاناتي",
		assigned: "المعينة",
		correction: "المصححة",
		previous: "السابقة",
		done: "تم",
		published: "تم نشره",
		approved: "موافق عليه",
		examTitle: "عنوان الامتحان",
		fromDate: "من تاريخ",
		toDate: "الى تاريخ",
		emptyState: " للأسف ، لا توجد بيانات",
		draft: "مسودة",
	},
	fetchHomework: {
		to: "إلى",
		fromDate: "من تاريخ",
		endDate: "تاريخ الإنتهاء",
		homework: "الواجب",
		selectClass: "يرجى إختيار فصل",
		myHomework: "واجباتي",
		othersHomeWork: "واجبات الآخرين",
		type: "النوع",
		degree: "الدرجة",
		date: "التاريخ",
		target: "الهدف",
		quiz: "اختبار",
		teacher: "المدرس",
		from: "من",
		emptyState: "لا توجد واجبات منزلية لنقلها",
	},
	createExam: {
		createExam: "انشاء امتحان",
		examTitle: "عنوان الامتحان",
		examTime: "وقت الامتحان",
		monthelyExam: "امتحان شهري",
		fixed: "ثابت",
		day: "يوم",
		range: "نطاق",
		addClasses: "اضافة فصول",
		finalExam: "الاختبار النهائي",
		midtermExam: "اختبار نصف الفصل",
		monthlyExam: "اختبار شهري",
		grade: "الصف",
		targetBy: "التعيين ",
		classes: " الفصول",
		finalTrainingExam: "تدريب الاختبار النهائي",
		midtermTrainingExam: "تدريب اختبار نصف الفصل",
		monthlyTrainingExam: "تدريب الاختبار الشهري",
		teacher: "مدرس",
		randomSort: "عشوائي",
		sameSort: "مرتب",
		correctors: "المصححون",
		selectcorrectorsMode: "يرجى تحديد وضع المصححون",
		correctorsMode: " وضع المصححون",
		questions: "الأسئلة",
		percentage: "النسبة المئوية",
		done: "تم",
		removeAll: "مسح الكل",
		addTeacher: "اضافة مدرس",
		assignTeacher: "تعيين للمعلم",
		teacherSelected: "مدرس محدد",
		selectTeacher: "حدد مدرس",
		resetAll: "إعادة ضبط الجميع",
		percentageError: "يجب أن تكون النسبة المئوية الإجمالية 100٪  ",
		confirmDataLoss: "تأكيد فقدان البيانات ",
		confirmMessage: "هل أنت متأكد أنك تريد التبديل؟",
		Assigned: "مخصص",
		AssignedToYou: "تم تخصيصه اليك",
		inprogress: "قيد التنفيذ",
		waitingForApproval: "في انتظار الموافقة",
		Approved: "نمت الموافقة",
		RequiredEdit: "يجب التعديل",
		NotAssigned: "غير مخصص",
		assignedTo: "مخصص ل",
		requiredEdit: "مطلوب التعديل",
		RequiredEditReason: "سبب التعديل",
		Convert: "تحويل",
	},
	superCorList: {
		Exam: "امتحان",
		Exams: "امتحانات",
		Date: "التاريخ",
		Time: "الوقت",
		Students: "الطلاب ",
		Correctors: "المصححين",
		Duration: "المدة",
		NoOfQuestions: "عدد الأسألة",
		Correction: "التصحيح",
		Assigned: "المعيين",
		Teacher: " المدرس",
		All: "كل ",
		Summary: "الملخص",
		Question: "السؤال",
		Progress: "المنفز",
		DonePercentage: "النسبة المئوية المنجزة",
		LastTimeCorrection: "اخر وقت للتصحيح",
		Status: "الحالة",
		Number: "رقم",
		AutoCorrected: "التصحيح التلقائي",
		ManualQuestion: "سؤال يدوي",
		Grade: "الدرجة",
		Done: "تم",
		NotViewed: "لم يرى",
		InProgress: "جاري التنفيز",
		ModifyCorrectors: "تعديل المصححيين",
		Hours: "ساعات",
		AssignedTeachers: "المدرسين المعينين",
		AssignedExams: "الامتحانات المعينة",
		NotCorrected: "غير مصحح",
	},
};
